import * as yup from 'yup';
import { PERMISOS } from 'const/Permisos';

const SchemaResiduoGenerador = isTienePermisos => {
  const schema = {
    tieneSubTipoResiduo: yup.boolean()
  };
  /**
   * *************************************
   * Identificación del residuo o recurso
   * *************************************
   **/

  if (isTienePermisos(PERMISOS.nombreLER)) {
    schema.nombreLER = yup
      .object()
      .typeError('Seleccione nombre LER')
      .required('Este campo es obligatorio');
  }

  if (isTienePermisos(PERMISOS.nombreLER)) {
    schema.nombreSubLER = yup
      .object()
      .typeError('Seleccione Subnombre LER')
      .required('Este campo es obligatorio');
  }

  if (isTienePermisos(PERMISOS.nombreLER)) {
    schema.nombreSubSubLER = yup
      .object()
      .typeError('Seleccione Subnombre LER')
      .required('Este campo es obligatorio');
  }

  if (isTienePermisos(PERMISOS.tipoResiduo)) {
    schema.tipoResiduo = yup
      .object()
      .typeError('Seleccione el residuo')
      .required('Este campo es obligatorio');
  }

  if (isTienePermisos(PERMISOS.tipoResiduo)) {
    schema.subTipoResiduo = yup
      .object()
      .typeError('Seleccione un subtipo de residuo')
      .required('Este campo es obligatorio');
  }

  if (isTienePermisos(PERMISOS.recursos)) {
    schema.tipoRecurso = yup
      .array()
      .when(['tieneTipoRecursos', 'otroTipoRecurso'], {
        is: (tieneTipoRecursos, otroTipoRecurso) =>
          tieneTipoRecursos && !otroTipoRecurso.length,
        then: () => {
          return yup
            .array()
            .of(yup.object())
            .min(1, 'Debe seleccionar al menos un recurso')
            .required('Este campo es obligatorio');
        }
      });
  }

  if (isTienePermisos(PERMISOS.recursos)) {
    schema.otroTipoRecurso = yup.array().when('tieneTipoRecursos', {
      is: false,
      then: () => {
        return yup
          .array()
          .of(yup.object())
          .min(1, 'Debe seleccionar al menos un recurso distinto')
          .required('Este campo es obligatorio');
      }
    });
  }

  if (isTienePermisos(PERMISOS.recursos)) {
    schema._otraMateriaPrima = yup.string().min(3, 'El nombre es muy corto');
  }

  if (isTienePermisos(PERMISOS.descripcionResiduo)) {
    schema.descripcionAdicional = yup
      .string()
      .min(3, 'El nombre es muy corto')
      // .max(100,'El nombre no puede ser tan largo'),
      .required('Este campo es obligatorio');
  }

  /**
   * ************************
   * Espesificaciones Oferta
   * ************************
   **/
  if (isTienePermisos(PERMISOS.sucursalOrigenEmpresa)) {
    schema.sucursal = yup
      .object()
      .typeError('Este campo es obligatorio')
      .required('Este campo es obligatorio');
  }

  if (isTienePermisos(PERMISOS.precioReferencial)) {
    schema.precioReferencial = yup
      .string()
      .required('Ingrese precio referencial');
  }

  if (isTienePermisos(PERMISOS.frecuencia)) {
    schema.frecuencia = yup.string().required('Ingrese frecuencia');
  }

  if (isTienePermisos(PERMISOS.cantidad)) {
    schema.cantidad = yup
      .number()
      .min(0.01, 'Ingrese cantidad debe ser mayor a 0')
      .required('Ingrese cantidad')
      .typeError('Ingrese cantidad');
  }

  if (isTienePermisos(PERMISOS.unidadMedida)) {
    schema.unidadMedida = yup
      .object()
      .required('Este campo es obligatorio')
      .typeError('Este campo es obligatorio');
  }

  if (isTienePermisos(PERMISOS.formatoEntrega)) {
    schema.formatoEntrega = yup
      .string()
      .min(3, 'El formato de entrega es muy corto')
      .required('Ingrese formato de entrega');
  }

  /**
   * *************************************
   * Material Adjunto
   * *************************************
   **/

  /**
   * *************************************
   * EnvioFormulario
   * *************************************
   **/

  return yup.object().shape(schema);
};

export { SchemaResiduoGenerador };

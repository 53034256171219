import { AuthContext } from 'context';
import { useContext } from 'react';

export const MenuUsuarioLogeado = ({ children }) => {
  const { logged } = useContext(AuthContext);

  if (!logged) {
    return null;
  }

  return (
    <div className="hidden lg:block md:col-span-0 lg:col-span-7">
      <div className="flex justify-center">{children}</div>
    </div>
  );
};

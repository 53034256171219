import * as yup from 'yup';

const schemaCalculoIndicadores = yup.object().shape({
  cantidad: yup
    .number()
    .min(0.01, 'Ingrese cantidad debe ser mayor a 0')
    .required('Este campo es obligatorio'),

  tipoResiduo: yup
    .object()
    .typeError('Seleccione el residuo')
    .required('Este campo es obligatorio'),

  subTipoResiduo: yup
    .object()
    .typeError('Seleccione un subtipo de residuo')
    .required('Este campo es obligatorio'),

  regionOferente: yup
    .object()
    .typeError('Seleccione la región')
    .required('Este campo es obligatorio'),

  comunaOferente: yup
    .object()
    .typeError('Seleccione la comuna')
    .required('Este campo es obligatorio'),

  nombreNumeroViaOferente: yup.string().required('Este campo es obligatorio'),

  regionDemandante: yup
    .object()
    .typeError('Seleccione la región')
    .required('Este campo es obligatorio'),
  comunaDemandante: yup
    .object()
    .typeError('Seleccione la comuna')
    .required('Este campo es obligatorio'),
  nombreViaDemandante: yup.string().required('Este campo es obligatorio'),

  tipoTransporte: yup
    .object()
    .typeError('Seleccione el tipo de transporte')
    .required('Este campo es obligatorio'),

  tipoTratamiento: yup
    .object()
    .typeError('Seleccione el tipo de tratamiento')
    .required('Este campo es obligatorio'),

  nTrabajadoresHombresTratamiento: yup
    .number()
    .min(0, 'Ingrese cantidad debe ser mayor a 0')
    .required('Este campo es obligatorio'),

  nTrabajadoresMujeresTratamiento: yup
    .number()
    .min(0, 'Ingrese cantidad debe ser mayor a 0')
    .required('Este campo es obligatorio'),

  cantidadToneladasTratamiento: yup
    .number()
    .min(0, 'Ingrese cantidad debe ser mayor a 0')
    .required('Este campo es obligatorio'),

  totalRemuneracionesPagadasTratamiento: yup
    .number()
    .min(0, 'Ingrese cantidad debe ser mayor a 0')
    .required('Este campo es obligatorio')
});

export { schemaCalculoIndicadores };

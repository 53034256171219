import React, { useContext } from 'react';
import ExitToApp from '@mui/icons-material/ExitToApp';
import Create from '@mui/icons-material/Create';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import NotificationArea from './NotificationArea';
import { PopperComponent } from 'components/design-system/Popper/Popper';
import ListSubheaderComponent from 'components/design-system/ListSubheader/ListSubheader';
import ListItemButtonComponent from 'components/design-system/ListItemButton/ListItemButton';
import { Avatar } from '@mui/material';
import { AuthContext } from 'context';
import { PERMISOS } from 'const/Permisos';
import { VerificarPermisos } from 'components/VerificarPermisos/VerificarPermisos';

const MiCuenta = ({
  handleLogout,
  forceRefresh,
  history,
  match,
  isSuperAdmin
}) => {
  const { logeduser, isTienePermisos } = useContext(AuthContext);
  const profileImage = logeduser.base64Avatar;
  const profileBase64 = 'data:image/png;base64,' + profileImage;

  return (
    <div className="flex justify-end">
      {isTienePermisos(PERMISOS.salasReunion) && (
        <NotificationArea
          history={history}
          match={match}
          isSuperAdmin={isSuperAdmin}
        />
      )}
      <PopperComponent
        Button={
          <div aria-controls="difusion-menu" aria-haspopup="true">
            <Avatar src={profileBase64} />
          </div>
        }
      >
        <Grid item lg={12} md={12} sm={12} xs={12} className="pt-2 px-3">
          <ListSubheaderComponent>Mi Cuenta</ListSubheaderComponent>
          <div className="pop-section-item">
            <List>
              <VerificarPermisos nombrePermiso={PERMISOS.editarCuenta}>
                <ListItemButtonComponent
                  onClick={forceRefresh('/perfil/editar')}
                  icon={<Create />}
                  label={'Editar Cuenta'}
                />
              </VerificarPermisos>

              <ListItemButtonComponent
                onClick={handleLogout}
                icon={<ExitToApp />}
                label={'Cerrar sesión'}
              />
            </List>
          </div>
        </Grid>
      </PopperComponent>
    </div>
  );
};

export default MiCuenta;

import API from './api';

const authentication = {
  login: function (loginData, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: '/iniciar-sesion',
      data: loginData,
      interceptor: conInterceptor
    });
  },

  refrescarSesion: function (token, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/refrescar-sesion',
      headers: {
        Authorization: 'Bearer ' + token
      },
      interceptor: conInterceptor
    });
  },

  logout: function (conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: '/logout',
      interceptor: conInterceptor
    });
  },

  recuperarPass: function (email, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: '/usuarios/correo-password',
      data: {
        correoElectronico: email
      },
      interceptor: conInterceptor
    });
  },

  iniciarSesion: function (loginData, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: '/iniciar-sesion',
      data: loginData,
      interceptor: conInterceptor
    });
  }
};

export default authentication;

import API from './api';

const documentos = {
  get: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/lista-documentos',
      params: params,
      interceptor: conInterceptor
    });
  },

  guardarDocumento: function (data, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: `/archivos-indexados`,
      data: data,
      interceptor: conInterceptor
    });
  },

  actualizarDocumento: function (uid, data, conInterceptor = true) {
    return API.axios({
      method: 'put',
      url: `/archivos-indexados/${uid}`,
      data: data,
      interceptor: conInterceptor
    });
  },

  getDocumento: function (uid, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: `/archivos-indexados/${uid}`,
      interceptor: conInterceptor
    });
  },

  obtenerDocumentos: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/archivos-indexados',
      params: params,
      interceptor: conInterceptor
    });
  },

  guardar: function (data, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: '/archivos',
      data: data,
      interceptor: conInterceptor
    });
  },

  obtenerArchivos: function (data, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/archivos',
      params: data,
      interceptor: conInterceptor
    });
  },
  editarArchivos: function (codigoArchivo, data, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/archivos/' + codigoArchivo,
      data: data,
      interceptor: conInterceptor
    });
  },
  eliminaArchivos: function (codigoArchivo, conInterceptor = true) {
    return API.axios({
      method: 'delete',
      url: '/archivos/' + codigoArchivo,
      interceptor: conInterceptor
    });
  },

  guardarLucene: function (data, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: '/guardar-documentos',
      data: {
        ...data,
        origen: 'documentos'
      },
      interceptor: conInterceptor
    });
  },

  editarLucene: function (data, conInterceptor = true) {
    return API.axios({
      method: 'patch',
      url: '/actualiza-documento',
      data: data,
      interceptor: conInterceptor
    });
  },

  delete: function (uidDocumento, conInterceptor = true) {
    return API.axios({
      method: 'delete',
      url: '/archivos-indexados/' + uidDocumento,
      interceptor: conInterceptor
    });
  },

  // GET - Material Información (Lista paginada)

  obtenerListaMaterialInformacion: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/material-informacion',
      params: params,
      interceptor: conInterceptor
    });
  },

  // GET - Material informacion (un registro)
  obtenerMaterialInformacion: function (
    codigoMaterialInformacion,
    conInterceptor = true
  ) {
    return API.axios({
      method: 'get',
      url: `/material-informacion/${codigoMaterialInformacion}`,
      interceptor: conInterceptor
    });
  },

  // DELETE - Material informacion
  eliminarMaterialInformacion: function (
    codigoMaterialInformacion,
    conInterceptor = true
  ) {
    return API.axios({
      method: 'delete',
      url: `/material-informacion/${codigoMaterialInformacion}`,
      interceptor: conInterceptor
    });
  },

  // POST - Material informacion
  guardarMaterialInformacion: function (data, conInterceptor = true) {
    return API.axios({
      method: 'post',
      url: `/material-informacion`,
      data: data,
      interceptor: conInterceptor
    });
  },

  // PUT - Material informacion
  actualizarMaterialInformacion: function (
    codigoMaterialInformacion,
    data,
    conInterceptor = true
  ) {
    return API.axios({
      method: 'put',
      url: `/material-informacion/${codigoMaterialInformacion}`,
      data: data,
      interceptor: conInterceptor
    });
  },

  //GET - Material informacion (solo base 64)
  obtenerMaterialInformacionBase64: function (
    codigoMaterialInformacion,
    conInterceptor = true
  ) {
    return API.axios({
      method: 'get',
      url: `/material-informacion/base64/${codigoMaterialInformacion}`,
      interceptor: conInterceptor
    });
  },

  //GET - Marcos legales (Lista paginada)
  obtenerListaMarcosLegales: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/marcos-legales',
      params: params,
      interceptor: conInterceptor
    });
  },

  // GET - Publicaciones (Lista paginada)
  obtenerListaPublicaciones: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/publicaciones',
      params: params,
      interceptor: conInterceptor
    });
  },

  // GET - Normativas tecnicas (Lista paginada)
  obtenerListaNormativasTecnicas: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/normativas-tecnicas',
      params: params,
      interceptor: conInterceptor
    });
  }
};

export default documentos;

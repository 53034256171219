import { useCallback, useContext, useEffect, useState } from 'react';
import { ObtieneUltimaTransaccion } from '../utils/ObtieneUltimaTransaccion';
import serviceRSD from 'services/residuos';
import { DICTONARY } from 'const/Dictonary';
import empresasService from 'services/empresas';
import { AuthContext } from 'context';

export const ServicioResumenTransaccion = codigoTransaccion => {
  const [transaccion, setTransaccion] = useState(false);
  const [cargandoResumen, setCargandoResumen] = useState(true);

  const handleResumenTransaccion = useCallback(async () => {
    setCargandoResumen(true);
    try {
      const response = await serviceRSD.resumenTransaccion(codigoTransaccion);
      if (response.status === 200) {
        const data = response.data;
        setTransaccion(data);
      } else {
        setTransaccion([]);
      }
    } catch (error) {
      setTransaccion([]);
      console.log(error);
    } finally {
      setCargandoResumen(false);
    }
  }, [codigoTransaccion]);

  useEffect(() => {
    handleResumenTransaccion();
  }, [handleResumenTransaccion]);

  return {
    transaccion,
    cargandoResumen
  };
};

export const ServicioListaTransacciones = (
  setData,
  setTotal,
  setCargaListaTransacciones
) => {
  const {
    perfiles: { isDemandante, isOferente }
  } = useContext(AuthContext);
  const [listaTransacciones, setListaTransacciones] = useState({
    dataTabla: [],
    dataMapa: []
  });

  const handleListaTransaccion = useCallback(
    async params => {
      setCargaListaTransacciones(true);
      try {
        const response = await empresasService.obtenerTransacciones(
          params,
          DICTONARY.INTERCEPTOR.DESACTIVADO
        );
        if (response.status === 200) {
          const { data } = response;
          const { content, totalElements } = data;
          if (!content) {
            throw new Error('No hay registros');
          }
          // Logica para mostrar nombre de empresas y sucursales
          const demandanteLogeado = isDemandante;
          const oferenteLogeado = isOferente;
          const isSuperAdmin = !demandanteLogeado && !oferenteLogeado;
          const mapaTransacciones = [];

          let infoOferente = {};
          let infoDemandante = {};
          let nombreEmpresaOferente = '';
          let nombreEmpresaDemandante = '';
          let nombreRecurso = '';

          const filterContent = content.map(c => {
            let ultimaTrasaccion = ObtieneUltimaTransaccion(
              c.codigoTransaccion,
              c.transaccionEstado
            );
            const sucursalTransaccion = c.sucursalTransaccion;

            // Nombres de contraparte en caso que el usuario sea oferente
            const demandante = sucursalTransaccion.find(
              s => s.nombreRol === DICTONARY.ROL.RECEPTOR.NOMBRE
            );
            infoDemandante = {
              nombre: demandante?.sucursal?.empresa?.nombreEmpresa || '',
              direccion: `${demandante?.sucursal?.direccion?.nombreVia}, ${demandante?.sucursal?.direccion?.comuna?.nombreComuna}, Región ${demandante?.sucursal?.direccion?.comuna?.region?.nombreRegion}`
            };

            const oferta = sucursalTransaccion.find(
              s => s.nombreRol === DICTONARY.ROL.GENERADOR.NOMBRE
            );
            infoOferente = {
              nombre: oferta?.sucursal?.empresa?.nombreEmpresa || '',
              direccion: `${oferta?.sucursal?.direccion?.nombreVia}, ${oferta?.sucursal?.direccion?.comuna?.nombreComuna}, Región ${oferta?.sucursal?.direccion?.comuna?.region?.nombreRegion}`
            };

            // Nombres de contraparte en caso que el usuario sea superadmin
            if (isSuperAdmin) {
              const oferta = sucursalTransaccion.find(
                s => s.nombreRol === DICTONARY.ROL.GENERADOR.NOMBRE
              );
              nombreEmpresaOferente = ` ${
                oferta?.sucursal.empresa.razonSocial ?? ''
              } / ${oferta?.sucursal.nombreSucursal ?? ''}   `;

              const demandante = sucursalTransaccion.find(
                s => s.nombreRol === DICTONARY.ROL.RECEPTOR.NOMBRE
              );
              nombreEmpresaDemandante = ` ${
                demandante?.sucursal.empresa.razonSocial ?? ''
              } / ${demandante?.sucursal.nombreSucursal ?? ''}`;
            }

            //Logica para desplegar el nombre del recurso y que coincida con el que se está buscando
            if (c.residuo.recursos.length > 1) {
              if (params.codigoRecurso) {
                let recursoFiltrado = c.residuo.recursos.find(
                  item => item.codigoRecurso === params.codigoRecurso
                );
                nombreRecurso = `${recursoFiltrado.nombreRecurso} (más otros ${c.residuo.recursos.length} recursos)`;
              } else {
                nombreRecurso = `${c.residuo.recursos[0].nombreRecurso} (más otros ${c.residuo.recursos.length} recursos)`;
              }
            } else {
              nombreRecurso = c.residuo.recursos[0].nombreRecurso;
            }

            mapaTransacciones.push(
              {
                codigoTransaccion: c.codigoTransaccion,
                rol: DICTONARY.ROL.GENERADOR.NOMBRE,
                latitud: oferta?.sucursal?.direccion?.latitudDireccion,
                longitud: oferta?.sucursal?.direccion?.longitudDireccion
              },
              {
                codigoTransaccion: c.codigoTransaccion,
                rol: DICTONARY.ROL.RECEPTOR.NOMBRE,
                latitud: demandante?.sucursal?.direccion?.latitudDireccion,
                longitud: demandante?.sucursal?.direccion?.longitudDireccion
              }
            );

            return {
              infoOferente: infoOferente,
              infoDemandante: infoDemandante,
              ultimaTrasaccion: ultimaTrasaccion,
              ...c,
              residuo: {
                ...c.residuo,
                nombreRecurso: nombreRecurso
              }
            };
          });
          setListaTransacciones({
            dataTabla: filterContent,
            dataMapa: agruparPorCoordenadas(mapaTransacciones)
          });
          setData({
            dataTabla: filterContent,
            dataMapa: agruparPorCoordenadas(mapaTransacciones)
          });
          setTotal(totalElements);
        }
      } catch (error) {
        console.log('ERROR', error);
        setListaTransacciones({
          dataTabla: [],
          dataMapa: []
        });
        setData({
          dataTabla: [],
          dataMapa: []
        });
        setTotal(0);
      } finally {
        setCargaListaTransacciones(false);
      }
    },
    [setCargaListaTransacciones, setData, setTotal]
  );

  const agruparPorCoordenadas = datos => {
    const grupos = {};

    datos.forEach(dato => {
      const key = `${dato.latitud}_${dato.longitud}_${dato.rol}`;

      if (!grupos[key]) {
        grupos[key] = {
          codigoTransaccion: [],
          rol: dato.rol,
          latitud: dato.latitud,
          longitud: dato.longitud
        };
      }

      grupos[key].codigoTransaccion.push(dato.codigoTransaccion);
    });

    return Object.values(grupos);
  };

  return {
    handleListaTransaccion,
    listaTransacciones,
    setData,
    setCargaListaTransacciones
  };
};

import ArrowOutward from '@mui/icons-material/ArrowOutward';
import CallReceived from '@mui/icons-material/CallReceived';
import LocalShipping from '@mui/icons-material/LocalShipping';
import Settings from '@mui/icons-material/Settings';
import People from '@mui/icons-material/People';
import DoneOutlined from '@mui/icons-material/DoneOutlined';

import RecyclingIcon from '@mui/icons-material/Recycling';
import IMG_TRANSACCION_INICIADO from 'assets/images/estadoTransacciones/iniciado.svg';
import IMG_TRANSACCION_CERRADO_CONCRETADO from 'assets/images/estadoTransacciones/cerrado-concretado.svg';
import IMG_TRANSACCION_CERRADO_NOCONCRETADO from 'assets/images/estadoTransacciones/cerrado-no-concretado.svg';
import IMG_TRANSACCION_FINALIZADO_CONCRETADO from 'assets/images/estadoTransacciones/finalizado-concretado.svg';
import IMG_TRANSACCION_FINALIZADO_NOCONCRETADO from 'assets/images/estadoTransacciones/finalizado-no-concretado.svg';
import { DEFAULT_IMAGE } from 'helpers/defaultImage';
import {
  HighlightOffOutlined,
  InfoOutlined,
  OpenInFull,
  WarningAmberOutlined
} from '@mui/icons-material';

export const DICTONARY = {
  //Nieveles de agrupación en sidebar
  LIST: {
    PRIMARY_LEVEL: 1,
    SECONDARY_LEVEL: 2
  },

  TEXT: {
    SIZE_DEFAULT: 'M'
  },

  HEADING: {
    SIZE_DEFAULT: 'h1'
  },

  DROPZONE: {
    //https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    FILE_TYPES_ICONS: {
      jpg: 'jpg',
      jpeg: 'jpg',
      png: 'png',
      pdf: 'pdf',
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'application/pdf': 'pdf',
      'application/vnd.ms-excel': 'xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        'xls',
      'application/msword': 'doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        'doc',
      'application/vnd.ms-powerpoint': 'ppt',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        'ppt'
    },

    //Config para carga de documentos en registro
    CONFIG_UPLOAD_BUSINESS_FILES: {
      maxFiles: 4,
      maxSize: 4_194_304,
      accept: {
        'image/*': [],
        'application/pdf': ['.pdf']
      }
    },

    CONFIG_UPLOAD_ARCHIVOS_ADJUNTOS: {
      maxFiles: 1,
      maxSize: 26_214_400, // 25 MB
      accept: {
        'image/jpeg': [],
        'image/jpg': [],
        'application/pdf': ['.pdf']
      }
    },

    CONFIG_UPLOAD_MATERIAL_INFORMACION: {
      maxFiles: 1,
      maxSize: 26_214_400, // 25 MB
      accept: {
        'image/jpeg': [],
        'image/png': [],
        'image/jpg': [],
        'application/pdf': ['.pdf']
      }
    },

    CONFIG_UPLOAD_PROFILE_FILES: {
      maxFiles: 1,
      maxSize: 5_242_880,
      accept: {
        'image/jpeg': [],
        'image/png': []
      }
    },
    //Ingreso de Residuos

    //Config para carga de documentos en registro
    CONFIG_UPLOAD_CREATE_RSD_IMAGES: {
      maxFiles: 4,
      maxSize: 4_194_304,
      accept: {
        'image/jpg': [],
        'image/jpeg': [],
        'image/png': [],
        'image/gif': []
      }
    },

    CONFIG_UPLOAD_CREATE_RSD_OTROS_ARCHIVOS: {
      maxFiles: 4,
      maxSize: 26_214_400,
      accept: {
        'application/pdf': ['.pdf']
      }
    },

    CONFIG_UPLOAD_CREATE_RSD_ARCHIVOS: {
      maxFiles: 4,
      maxSize: 4_194_304,
      accept: {
        'image/jpeg': []
      }
    },

    CONFIG_UPLOAD_CARGA_MASIVA: {
      maxFiles: 1,
      maxSize: 524_288_000,
      accept: {
        'text/plain': ['.txt']
      }
    },

    CONFIG_UPLOAD_RECURSOS_ADQUIRIDOS: {
      maxFiles: 4,
      maxSize: 4_194_304,
      accept: {
        'image/jpeg': [],
        'application/pdf': ['.pdf']
      }
    }
  },

  VERIFYMAIL: {
    TIMER: {
      segundos: 300 // tiempo inicial en segundos
    },

    REINTENTOS: {
      minimo: 0,
      maximo: 2
    },

    PROPSDUMMY: {
      // se puede utilizar en caso de que el paso anterior a la validación aun no este disponible
      correo: 'test@test.com'
    }
  },

  //Definición de iconos por role
  ROL: {
    GENERADOR: {
      ICONO: params => <ArrowOutward {...params} />,
      NOMBRE: 'generador',
      TITULO: 'Generador',
      ACCION: 'ofrece',
      VERBO_1: 'ofertado',
      CODIGO: 1
    },
    RECEPTOR: {
      ICONO: params => <CallReceived {...params} />,
      NOMBRE: 'receptor',
      TITULO: 'Receptor',
      ACCION: 'requiere',
      VERBO_1: 'requerido',
      CODIGO: 2
    },
    TRANSPORTISTA: {
      ICONO: params => <LocalShipping {...params} />,
      NOMBRE: 'transportista',
      TITULO: 'Transportista',
      ACCION: 'transporta',
      CODIGO: 3
    },
    GESTOR: {
      ICONO: params => <Settings {...params} />,
      NOMBRE: 'gestor',
      TITULO: 'Gestor',
      ACCION: 'gestiona',
      CODIGO: 4
    },
    ASESOR: {
      ICONO: params => <People {...params} />,
      NOMBRE: 'asesor',
      TITULO: 'Asesor',
      ACCION: 'asesora',
      CODIGO: 5
    },
    OFERENTEDEMANDANTE: {
      ICONO: params => <OpenInFull {...params} />,
      NOMBRE: 'oferentedemandante',
      TITULO: 'Oferente/Demandante',
      ACCION: 'ofrece & demanda',
      CODIGO: 12
    },
    RECICLADORDEBASE: {
      ICONO: params => <RecyclingIcon {...params} />,
      NOMBRE: 'reciclador de base',
      TITULO: 'Reciclador de base',
      ACCION: 'recicla',
      CODIGO: 6
    }
  },

  ROLES_KEY_NAME: [
    null,
    'generador',
    'receptor',
    'transportista',
    'gestor',
    'asesor',
    'recicladordebase'
  ],

  ROLES: [
    'asesor',
    'receptor',
    'gestor',
    'generador',
    'transportista',
    'reciclador de base'
  ],

  ROLES_PERMITIDOS_CON_DOCUMENTOS: [
    'TRANSPORTISTA',
    'GESTOR',
    'ASESOR',
    'RECICLADOR DE BASE',
    'RECEPTOR'
  ],

  //Definición de iconos por estado
  ESTADO: {
    ERROR: {
      ICONO: params => (
        <HighlightOffOutlined className="text-error-dark" {...params} />
      ),
      CLASS: 'error'
    },
    SUCCESS: {
      ICONO: params => (
        <DoneOutlined className="text-success-dark" {...params} />
      ),
      CLASS: 'success'
    },
    INFO: {
      ICONO: params => <InfoOutlined className="text-info-dark" {...params} />,
      CLASS: 'info'
    },
    WARNING: {
      ICONO: params => (
        <WarningAmberOutlined className="text-warning-dark" {...params} />
      ),
      CLASS: 'warning'
    }
  },

  //Atribución por uso de Leaft Map
  MAPA: {
    TITLE_URL:
      'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
    ATTRIBUTION:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
    DEFAULT_CORDENADAS: {
      latitudDireccion: -33.014146325979155,
      longitudDireccion: -71.54261792794125
    },
    SANTIAGO_CORDENADAS: [-33.455119, -70.669096]
  },

  //Configuración para selector de radio de alcance.
  MAPA_RADIO: {
    RANGOS: [
      { value: 0, label: '0 KM' },
      { value: 10, label: '10 KM' },
      { value: 20, label: '20 KM' },
      { value: 30, label: '30 KM' },
      { value: 40, label: '40 KM' }
    ],
    DEFAULT: 10
  },

  //Configuraciones para MATCH
  MATCH: {
    NUMERO_DECIMALES: 2,
    UNIDAD_DISTANCIA: 'Km',
    UNIDAD_DISPONIBILIDAD: 'dias',
    TABS: {
      RESIDUOS: 'RESIDUOS',
      EMPRESAS: 'EMPRESAS',
      AMPLIADA: 'AMPLIADA'
    }
  },

  ESTADOS: {
    INIT: 'INIT',
    CARGANDO: 'CARGANDO',
    ERROR: 'ERROR',
    EXITO: 'EXITO'
  },

  // Configuración de Usuarios

  USUARIOS: {
    LABEL_SUCURSAL: `Sucursal`,
    LABEL_EMAIL: `Correos sin sucursal existentes`,
    LABEL_EMAIL_NUEVOS: `Correo nuevo sin sucursal`,
    TEXTO_ALERTA_ENVIADO: `La solicitud ha sido enviada correctamente al correo indicado. 
                            La recepción del correo puede tomar un par de minutos 
                            y es posible que el usuario tenga que revisar la casilla de SPAM.`,
    TEXTO_ALERTA_NO_ENVIADO: `La solicitud no ha podido ser enviada correctamente`,
    TEXTO_BOTON_ENVIAR: `Enviar invitación`,
    TEXTO_ES_CORREO_NUEVO: `* es un nuevo correo electrónico`
  },

  //Dropdown campo tipo vía
  TIPO_VIA: [
    { codigoTipoVia: 1, nombreTipoVia: 'Avenida' },
    { codigoTipoVia: 2, nombreTipoVia: 'Calle' },
    { codigoTipoVia: 3, nombreTipoVia: 'Pasaje' }
  ],

  TIPO_ACREDITACION: {
    AUTORIZACION_ACOPIO: 'autorizacionAcopio',
    AUTORIZACION_TRANSPORTE: 'autorizacionTransporte',
    REGISTRO_NACIONAL_RBD: 'registroNacionalRDB',
    OTRAS_ACREDITACIONES: 'otrasAcreditaciones'
  },

  ESTADOS_TRANSACCION: {
    INICIADA: { NOMBRE: 'Iniciada', ICONO: IMG_TRANSACCION_INICIADO },
    'CERRADA OFERTA CONCRETADA': {
      NOMBRE: 'Cerrada Oferta Concretada',
      ICONO: IMG_TRANSACCION_CERRADO_CONCRETADO
    },
    'CERRADA DEMANDA CONCRETADA': {
      NOMBRE: 'Cerrada Demanda Concretada',
      ICONO: IMG_TRANSACCION_CERRADO_CONCRETADO
    },
    'CERRADA OFERTA NO CONCRETADA': {
      NOMBRE: 'Cerrada Oferta No Concretada',
      ICONO: IMG_TRANSACCION_CERRADO_NOCONCRETADO
    },
    'CERRADA DEMANDA NO CONCRETADA': {
      NOMBRE: 'Cerrada Demanda No Concretada',
      ICONO: IMG_TRANSACCION_CERRADO_NOCONCRETADO
    },
    'FINALIZADO CONCRETADO': {
      NOMBRE: 'Finalizado Concretado',
      ICONO: IMG_TRANSACCION_FINALIZADO_CONCRETADO
    },
    'FINALIZADO NO CONCRETADO': {
      NOMBRE: 'Finalizado No Concretado',
      ICONO: IMG_TRANSACCION_FINALIZADO_NOCONCRETADO
    }
  },

  ESTADOS_NO_APLICA_INICIAR_TRANSACCION_ARRAY: [
    'INICIADA',
    'CERRADA DEMANDA CONCRETADA',
    'CERRADA OFERTA CONCRETADA',
    'FINALIZADO CONCRETADO'
  ],

  TRANSACCIONES_PERMITIDAS: [
    'Cerrada Demanda Concretada',
    'Cerrada Demanda No Concretada',
    'Cerrada Oferta Concretada',
    'Cerrada Oferta No Concretada',
    'Finalizado Concretado',
    'Finalizado No Concretado'
  ],

  PERFILES: [
    { codigoPerfil: 1, nombrePerfil: 'Super Administrador' },
    { codigoPerfil: 2, nombrePerfil: 'Administrador' },
    { codigoPerfil: 3, nombrePerfil: 'Funcionario' },
    { codigoPerfil: 4, nombrePerfil: 'Usuario' },
    { codigoPerfil: 5, nombrePerfil: 'Analista Territorial' }
  ],

  CODIGOS_PERFILES: {
    SUPER_ADMINISTRADOR: 1,
    ADMINISTRADOR: 2,
    FUNCIONARIO: 3,
    USUARIO: 4,
    ANALISTA_TERRITORIAL: 5
  },

  DEFAULT_IMAGEN_PERFIL: DEFAULT_IMAGE,

  PAGINACION: {
    DEFAULT: {
      pageIndex: 0,
      pageSize: 10
    },
    ALL_DATA: 999_999_999
  },

  CHAT: {
    PREFIJO_NOMBRE_SALA_EMPRESAS: 'Empresas: ',
    PREFIJO_NOMBRE_SALA_RESIDUOS: 'Residuo: ',
    TIPO_MENSAJES: {
      USUARIO_SISTEMA: 'SISTEMA',
      USUARIO_ACTUAL: 'ACTUAL',
      USUARIO_CONTRAPARTE: 'CONTRAPARTE'
    }
  },

  INTERCEPTOR: {
    DESACTIVADO: false,
    ACTIVADO: true
  },

  TIPO_RESIDUOS: {
    NIVEL_1: 1,
    NIVEL_2: 2
  },

  NUMERO_DECIMALES: 2,
  NUMERO_DECIMALES_HOME: 1,
  NUMERO_SIN_DECIMALES: 0,

  EMAIL_REGEX: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/,

  // Carga Masiva
  CARGA_MASIVA: {
    NOMBRE_ARCHIVO_FORMATO_SII: 'formato-empresas-sii.txt',
    NOMBRE_ARCHIVO_FORMATO_RECT: 'formato-residuos.txt',
    NOMBRE_ARCHIVO_FORMATO_PS: 'formato-empresas-ps.txt'
  },

  COORDENAS_CHILE: {
    REGION_METROPOLITANA: [-33.4372, -70.6506],
    REGION_VALPARAISO: [-33.0477778, -71.6011111],
    TODAS: {
      1: {
        codigoRegion: 1,
        nombreRegion: 'Arica y Parinacota',
        coordenadas: [-18.517279, -69.685586]
      },
      2: {
        codigoRegion: 2,
        nombreRegion: 'Tarapacá',
        coordenadas: [-20.063314, -69.489439]
      },
      3: {
        codigoRegion: 3,
        nombreRegion: 'Antofagasta',
        coordenadas: [-23.643438, -70.390124]
      },
      4: {
        codigoRegion: 4,
        nombreRegion: 'Atacama',
        coordenadas: [-27.370249, -70.329507]
      },
      5: {
        codigoRegion: 5,
        nombreRegion: 'Valparaíso',
        coordenadas: [-33.055377, -71.605749]
      },
      6: {
        codigoRegion: 6,
        nombreRegion: 'Coquimbo',
        coordenadas: [-29.969069, -71.331478]
      },
      7: {
        codigoRegion: 7,
        nombreRegion: "Del libertador Gral. Bernador O'higgins",
        coordenadas: [-34.588481, -70.991689]
      },
      8: {
        codigoRegion: 8,
        nombreRegion: 'Del Maule',
        coordenadas: [-35.444796, -71.622366]
      },
      9: {
        codigoRegion: 9,
        nombreRegion: 'Ñuble',
        coordenadas: [-36.597756, -72.102513]
      },
      10: {
        codigoRegion: 10,
        nombreRegion: 'Del BioBío',
        coordenadas: [-37.459789, -72.356254]
      },
      11: {
        codigoRegion: 11,
        nombreRegion: 'De la Araucanía',
        coordenadas: [-38.739169, -72.612537]
      },
      12: {
        codigoRegion: 12,
        nombreRegion: 'De los Ríos',
        coordenadas: [-39.826755, -73.235006]
      },
      13: {
        codigoRegion: 13,
        nombreRegion: 'Metropolitana de Santiago',
        coordenadas: [-33.450824, -70.669644]
      },
      14: {
        codigoRegion: 14,
        nombreRegion: 'De los Lagos',
        coordenadas: [-41.457532, -72.939797]
      },
      15: {
        codigoRegion: 15,
        nombreRegion: 'Aysen',
        coordenadas: [-45.409076, -72.692779]
      },
      16: {
        codigoRegion: 16,
        nombreRegion: 'Magallanes y de la antártica chilena',
        coordenadas: [-53.138608, -70.917305]
      }
    }
  },

  DEFAULT_REGION: {
    REGION_METROPOLITANA: 13
  },

  JIRA_CONTACTO_URL:
    'https://residuo-recurso.atlassian.net/servicedesk/customer/portal/1/group/1/create/4',

  RESPONSIVE_BREAKPOINT: {
    LG: '(max-width: 1279px)',
    MD: '(max-width: 1023px)',
    SM: '(max-width: 767px)',
    XS: '(max-width: 639px)',
    XSS: '(max-width: 479px)'
  },

  MENSAJES_BOTONES_ACCIONES_CRUD: {
    EDITAR: 'Guardar cambios',
    EDITAR_IS_LOADING: 'Guardando cambios',
    DUPLICAR: 'Duplicar',
    DUPLICAR_IS_LOADING: 'Duplicando',
    INGRESAR: 'Ingresar',
    INGRESAR_IS_LOADING: 'Ingresando'
  },
  MENSAJES_EXITO_CRUD: {
    EDITAR: 'Se ha editado correctamente',
    DUPLICAR: 'Se ha duplicado correctamente'
  },
  MAPA_MENSAJES: {
    INFO_ACTIVAR_EDICION:
      'Si al ingresar los datos de Región, Comuna, Nombre y número calle la ubicación del pin en el mapa no es exacta o no se encontró la dirección, haz clic en Edición manual y ubica el pin donde corresponda.',
    INFO_DESACTIVAR_EDICION:
      'Al desactivar la edición manual la ubicación se actualizara automáticamente de acuerdo a los datos ingresados en los campos de Región, Comuna, Nombre y número calle.',
    DIRECCION_NO_ENCONTRADA:
      'Por favor haz clic en Edición manual y ubica el pin donde corresponda',
    DIRECCION_NO_ENCONTRADA_DESACTIVADA:
      'Por favor vuelva a activar la edición manual y ubique el pin donde corresponda'
  },

  //Sectores en línea base
  SECTOR_ECONOMICO: {
    NO_SELECIONADOS_POR_DEFECTO: ['10', '68']
  },

  LINEA_BASE_ESTADOS: {
    FILTRA_FORMULARIO: 'FILTRA_FORMULARIO',
    SELECCIONA_PUNTO: 'SELECCIONA_PUNTO',
    SELECCIONA_PUNTO_TABLA: 'SELECCIONA_PUNTO_TABLA',
    DESELECCIONA_PUNTO: 'DESELECCIONA_PUNTO',
    DEFAULT: ' DEFAULT'
  },

  CURRENT_YEAR: new Date().getFullYear(),
  CURRENT_MONTH: new Date().getMonth(),
  CURRENT_DAY: new Date().getDate(),
  INITIAL_DATE: new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  ),

  URL: {
    REPORTAR_PROBLEMA:
      'https://residuo-recurso.atlassian.net/servicedesk/customer/portal/1/group/1/create/5',
    PORTAL_DIFUSION: 'https://plataforma-industria-circular.cl/',
    PORTAL_DIFUSION_VIDEOS:
      'https://plataforma-industria-circular.cl/tutoriales/',
    PORTAL_DIFUSION_QUIENES_SOMOS:
      'https://plataforma-industria-circular.cl/#quienes-somos',
    PORTAL_DIFUSION_SALA_DE_PRENSA:
      'https://plataforma-industria-circular.cl/sala-de-prensa/',
    CONTACTO_COMERCIAL:
      'https://residuo-recurso.atlassian.net/servicedesk/customer/portal/1/group/1/create/4'
  },

  VALIDACION_ROLES: {
    GENERADOR: {
      CODIGO: 1,
      NOMBRE: 'Generador',
      ROL: '1',
      PERMISO: 'rolGenerador' // :TODO: SOLICITAR PERMISOS
    },
    RECEPTOR: {
      CODIGO: 2,
      NOMBRE: 'Receptor',
      ROL: '2',
      PERMISO: 'rolReceptor' // :TODO: SOLICITAR PERMISOS
    },
    TRANSPORTISTA: {
      CODIGO: 3,
      NOMBRE: 'Transportista',
      ROL: '3',
      PERMISO: 'rolTransportista' // :TODO: SOLICITAR PERMISOS
    },
    GESTOR: {
      CODIGO: 4,
      NOMBRE: 'Gestor',
      ROL: '4',
      PERMISO: 'rolGestor' // :TODO: SOLICITAR PERMISOS
    },
    ASESOR: {
      CODIGO: 5,
      NOMBRE: 'Asesor',
      ROL: '5',
      PERMISO: 'rolAsesor' // :TODO: SOLICITAR PERMISOS
    },
    RECICLADOR_DE_BASE: {
      CODIGO: 6,
      NOMBRE: 'Reciclador de base',
      ROL: '6',
      PERMISO: 'rolRecicladorDeBase' // :TODO: SOLICITAR PERMISOS
    },
    GENRADOR_RECEPTOR: {
      CODIGO: 12,
      NOMBRE: 'Generador/Receptor',
      ROL: '1,2',
      PERMISO: 'rolGeneradorReceptor' // :TODO: SOLICITAR PERMISOS
    },
    ACESOR_TRANSPORTISTA_GESTOR: {
      CODIGO: 534,
      NOMBRE: 'Asesor/Transportista/Gestor',
      ROL: '5,3,4',
      PERMISO: 'rolAsesorTransportistaGestor' // :TODO: SOLICITAR PERMISOS
    }
  },

  VALIDACION_DASHBOARD: {
    SUPER_ADMINISTRADOR: {
      CODIGO: 1,
      NOMBRE: 'Super Administrador',
      PERMISO: 'VerDashboardSuperAdministrador' // :TODO: SOLICITAR PERMISOS
    },
    ANALISTA_TERRITORIAL: {
      CODIGO: 2,
      NOMBRE: 'Analista Territorial',
      PERMISO: 'VerDashboardAnalistaTerritorial' // :TODO: SOLICITAR PERMISOS
    },
    RECICLADOR_DE_BASE: {
      CODIGO: 3,
      NOMBRE: 'Administrador',
      PERMISO: 'VerDashboardRecicladorDeBase' // :TODO: SOLICITAR PERMISOS
    }
  },

  TIPO_FORMULARIO: {
    editar: 'editar',
    ingresar: 'ingresar',
    duplicar: 'duplicar',
    operaciones: ['ingresar', 'editar', 'duplicar']
  },

  TOAST: {
    DURATION: 5000
  },

  SKELETON: {
    DUMMY_10: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  }
};

import API from './api';

const analisisTerritorial = {
  analisisEmpresa: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/empresas/analisis-territorial',
      params: params,
      interceptor: conInterceptor
    });
  },

  analisisEmpresaRETC: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/residuos/analisis-territorial',
      interceptor: conInterceptor,
      params: params
    });
  },

  mapaAnalisisEmpresas: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/empresas/analisis-territorial/mapa',
      params: params,
      interceptor: conInterceptor
    });
  },

  graficoDistribucionEmpresasResiduos: function (
    params,
    conInterceptor = true
  ) {
    return API.axios({
      method: 'get',
      url: '/provincias/analisis-territorial/grafico-distribucion',
      params: params,
      interceptor: conInterceptor
    });
  },

  potencialSinergias: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/empresas/analisis-territorial/potencial-sinergia',
      params: params,
      interceptor: conInterceptor
    });
  },

  graficoRelaciones: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/empresas/analisis-territorial/grafico-relaciones',
      params: params,
      interceptor: conInterceptor
    });
  },

  match: function (params, conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/empresas/analisis-territorial/match',
      params: params,
      interceptor: conInterceptor
    });
  }
};

export default analisisTerritorial;

import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Close,
  ExpandMore,
  NavigateNext,
  Visibility,
  VisibilityOff
} from '@mui/icons-material';
import {
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grow,
  Hidden,
  IconButton,
  InputAdornment,
  MenuList,
  Paper,
  Popper
} from '@mui/material';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import LabelComponent from 'components/design-system/Label/Label';
import LinkComponent from 'components/design-system/Link/Link';
import Text from 'components/design-system/Text/Text';
import authenticationService from 'services/authentication';
import { DICTONARY } from 'const/Dictonary';
import { AuthContext } from 'context';
import { RUTAS } from 'const/Rutas';

export const LoginForm = React.forwardRef(
  ({ handleChange, formState, setFormState }, ref) => {
    const history = useHistory();
    const [sendingEmail, setSendingEmail] = useState(false);
    const [loginErrorMsg, setLoginErrorMsg] = useState(null);
    const [inputFeedback, setInputFeedback] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [open, setOpen] = useState(false);

    // PARA EL FUNCIONAMIENTO DEL MENSAJE DE CONFIRMACIÓN
    const [confirmChangePassword, setConfirmChangePassword] = useState(null);

    const handleClickOpen = (row, table) => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleClickShowPassword = () => setShowPassword(show => !show);
    const handleMouseDownPassword = event => {
      event.preventDefault();
    };
    const { login } = useContext(AuthContext);

    const handleClickRecuperarPass = useCallback(async () => {
      if (sendingEmail) return;

      if (!formState.email || !formState.email.trim()) {
        setLoginErrorMsg('Ingrese un correo electronico');
        return;
      }
      // console.log('reenviando correo');
      setSendingEmail(true);
      authenticationService
        .recuperarPass(formState.email.trim())
        .then(result => {
          setInputFeedback({ tipo: 'success', mensaje: 'Correo enviado' });
          setSendingEmail(false);
        })
        .catch(error => {
          if (error.response && error.response.data.error.codigo === 'UV404') {
            setInputFeedback({
              tipo: 'error',
              mensaje: 'Correo no registrado'
            });
          } else {
            setInputFeedback({
              tipo: 'error',
              mensaje: 'No se pudo enviar el correo'
            });
          }
          setSendingEmail(false);
          setConfirmChangePassword(false);
        });
      setConfirmChangePassword(null);
    }, [formState.email, sendingEmail]);

    const handleIngresar = event => {
      setInputFeedback(null);
      authenticationService
        .iniciarSesion(
          {
            correoElectronico: (formState.email + '').trim(),
            password: formState.password
          },
          DICTONARY.INTERCEPTOR.ACTIVADO
        )
        .then(response => {
          if (response.data) {
            login(response.data);
            history.replace(RUTAS.HOME_USER);
          }
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data.error.codigo === 'UV403-1') {
              setInputFeedback({
                tipo: 'error',
                mensaje: 'Credenciales inválidas'
              });
            }
            if (error.response.data === 'Error_usuario_no_valido') {
              setInputFeedback({
                tipo: 'error',
                mensaje: 'Un administrador debe validar su cuenta'
              });
            }
            if (error.response.data.error.codigo === 'UV200-3') {
              setInputFeedback({
                tipo: 'error',
                mensaje: 'Confirmar cuenta con el correo enviado'
              });
            }
          }
          setFormState(formState => ({ ...formState, password: '' }));
        });
    };

    useEffect(() => {
      if (confirmChangePassword) {
        handleClickRecuperarPass();
      }
    }, [confirmChangePassword, handleClickRecuperarPass]);

    return (
      <div className="login-body" ref={ref}>
        <form
          onSubmit={event => {
            event.preventDefault();
            handleIngresar();
            return false;
          }}
        >
          <div className="mb-4">
            <InputComponent
              fullWidth
              onChange={handleChange}
              label={<LabelComponent>Email</LabelComponent>}
              name="email"
              type="text"
              value={formState.email || ''}
              variant="outlined"
              touched={true}
              error={!loginErrorMsg}
              estado={inputFeedback}
            />
          </div>
          <div className="mb-4">
            <InputComponent
              fullWidth
              label={<LabelComponent>Contraseña</LabelComponent>}
              onChange={handleChange}
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formState.password || ''}
              variant="outlined"
              outerInputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      size="small"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className="mb-3">
            <ButtonComponent
              fullWidth
              disabled={sendingEmail}
              onClick={handleIngresar}
              className="flex justify-between"
            >
              Iniciar Sesión
              <NavigateNext />
            </ButtonComponent>
            <input
              type="submit"
              value="Send Request"
              style={{ display: 'none' }}
            />
          </div>

          <Text>
            <LinkComponent
              type="a"
              onClick={() => {
                handleClickOpen();
              }}
            >
              {sendingEmail
                ? 'Enviando Correo...'
                : '¿Olvidaste tu contraseña?'}
            </LinkComponent>
          </Text>
        </form>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            className="border-b border-neutral-90"
          >
            <Heading type="h3" className="mb-0">
              {`Confirmación de cambio de contraseña `}
            </Heading>
          </DialogTitle>
          <DialogContent className="p-5">
            <DialogContentText id="alert-dialog-description" className="pt-4">
              <Text>
                {`¿Estás seguro que deseas reestablecer tu contraseña?`}
              </Text>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="border-t border-neutral-90 p-4 justify-between">
            <ButtonComponent
              type="secondary"
              onClick={handleClose}
              className="justify-center"
            >
              <Hidden smDown>Cancelar</Hidden>
              <Hidden smUp>
                <Close />
              </Hidden>
            </ButtonComponent>
            <ButtonComponent
              type="primary"
              onClick={() => {
                setConfirmChangePassword(true);
                handleClose();
              }}
              autoFocus
              className="w-full xs:w-auto justify-center"
            >
              Confirmar
            </ButtonComponent>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
);

export const LoginFormContainer = ({ children }) => {
  const { logeduser } = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (logeduser?.expiredUserEmail) {
      setTimeout(() => {
        setOpen(true);
      }, 1500);
    }
  }, [logeduser?.expiredUserEmail]);

  return (
    <>
      <div ref={anchorRef}>
        <ButtonComponent
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className="!px-2 sm:!px-4 !text-xs sm:text-sm mr-1"
        >
          <Hidden smDown>
            Iniciar Sesión
            <ExpandMore />
          </Hidden>
          <Hidden smUp>Ingresar</Hidden>
        </ButtonComponent>
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  <div className="loginPopUp">{children}</div>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

import Help from '@mui/icons-material/Help';
import Delete from '@mui/icons-material/Delete';
import DescriptionOutlined from '@mui/icons-material/DescriptionOutlined';
import Edit from '@mui/icons-material/Edit';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import Text from 'components/design-system/Text/Text';
import { TooltipComponent } from 'components/design-system/Tooltip/Tooltip';
import { useResponsive } from 'hooks/useResponsive';
import { useEffect, useState } from 'react';
import { DialogoCargarCertificado } from './DialogoCargarCertificado';
import { DialogoSolicitarCertificado } from './DialogoSolicitarCertificado';
import { MenuItem, Tooltip } from '@mui/material';
import Table from 'components/design-system/Table/Table';
import { useHelpersResiduosDeclarados } from '../hooks/useHelpersResiduosDeclarados';
import ContentCopy from '@mui/icons-material/ContentCopy';
import EmailIcon from '@mui/icons-material/Email';
import UploadFileSharpIcon from '@mui/icons-material/UploadFileSharp';
import residuosServices from 'services/residuos';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import { DialogoEliminarResiduo } from './DialogoEliminarResiduo';
import { Alerta } from 'utils/Alertas';

//Componentes Tabla
export const CuerpoNegocio = ({
  isCargando,
  residuos,
  pageTable,
  setNumPagina,
  recargarResiduos
}) => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5 //customize the default page size
  });

  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [residuoToAction, setResiduoToAction] = useState(null);
  const [isSolicitarOpen, setIsSolicitarOpen] = useState(false);
  const [registroEliminar, setRegistroEliminar] = useState(null);

  useEffect(() => {
    if (!isUploadOpen) {
      setResiduoToAction(null);
    }
  }, isUploadOpen);
  useEffect(() => {
    if (!isSolicitarOpen) {
      setResiduoToAction(null);
    }
  }, isSolicitarOpen);

  const { isLGDown } = useResponsive();

  const columnTables = [
    {
      header: 'Código',
      accessorKey: 'codigoDeclarado',
      enableGlobalFilter: false,
      Cell: ({ renderedCellValue }) => (
        <Text className="gap-2 flex items-center">
          {renderedCellValue ? renderedCellValue.codigo : '-'}
          {renderedCellValue.isResiduoDeclarado && (
            <TooltipComponent title="Datos provenientes de una transacción del marketplace">
              <HubOutlinedIcon className="text-uv-primary-0" />
            </TooltipComponent>
          )}
        </Text>
      ),
      enableColumnActions: false,
      enableColumnFilter: false,
      size: 50
    },
    {
      header: 'Residuo',
      accessorKey: 'residuo',
      enableGlobalFilter: false,
      Cell: ({ renderedCellValue }) => (
        <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
      ),
      enableColumnActions: false,
      enableColumnFilter: false
    },
    {
      header: 'Cantidad (unidad)',
      accessorKey: 'cantidad',
      enableGlobalFilter: false,
      Cell: ({ renderedCellValue }) => (
        <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
      ),
      enableColumnActions: false,
      enableColumnFilter: false
    },
    {
      header: 'Fecha de la transacción',
      accessorKey: 'fechaDisponibilidad',
      enableGlobalFilter: false,
      Cell: ({ renderedCellValue }) => (
        <Text>{renderedCellValue ? renderedCellValue : '-'}</Text>
      ),
      enableColumnActions: false,
      enableColumnFilter: false
    },
    {
      header: 'Certificado',
      Header: ({ column }) => (
        <>
          <Text className="text-gray-900 font-bold">Certificado</Text>
          <TooltipComponent
            title={
              'Puedes cargar el certificado de recepción del residuo u otro tipo de certificado asociado.'
            }
          >
            <Help className="text-uv-secondary-0" fontSize="small" />
          </TooltipComponent>
        </>
      ),
      accessorKey: 'certificado',
      enableGlobalFilter: false,
      Cell: ({ renderedCellValue, row, table }) => {
        return (
          <>
            {row.original?.certificadosRecepcion?.length > 0 ? null : (
              <TooltipComponent
                arrow
                placement="left"
                title="Solicitar Certificado"
              >
                <IconButtonComponent
                  size="small"
                  className="mr-2"
                  type="secondary"
                  onClick={() => {
                    setResiduoToAction(row.original);
                    setIsSolicitarOpen(true);
                  }}
                >
                  <EmailIcon />
                </IconButtonComponent>
              </TooltipComponent>
            )}

            {row.original?.certificadosRecepcion?.length > 0 ? null : (
              <TooltipComponent
                arrow
                placement="left"
                title="Cargar Certificado"
              >
                <IconButtonComponent
                  size="small"
                  className="mr-2"
                  type="secondary"
                  onClick={() => {
                    setResiduoToAction(row.original);
                    setIsUploadOpen(true);
                  }}
                >
                  <UploadFileSharpIcon />
                </IconButtonComponent>
              </TooltipComponent>
            )}
            {Array.isArray(row.original?.certificadosRecepcion)
              ? row.original?.certificadosRecepcion.map(
                  certificadoRecepcion => {
                    return (
                      <TooltipComponent
                        arrow
                        placement="left"
                        title={
                          'Descargar ' + certificadoRecepcion.nombreArchivo
                        }
                      >
                        <IconButtonComponent
                          size="small"
                          className="mr-2"
                          //disabled={isLoadingLista}
                          // type={isCargando ? 'disabled' : 'secondary'}
                          type="secondary"
                          onClick={() => {
                            console.log('Descargar', row.original);
                            console.log(
                              'certificadoRecepcion :>> ',
                              certificadoRecepcion
                            );

                            residuosServices
                              .descargarCertificado({
                                codigoCertificadoRecepcion:
                                  certificadoRecepcion.codigoCertificadoRecepcion,
                                codigoResiduoDeclarado: row.original.codigo
                              })
                              .then(result => {
                                console.log('result :>> ', result);

                                // the binari in result.data save has a file in browser
                                const url = window.URL.createObjectURL(
                                  new Blob([result.data])
                                );
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute(
                                  'download',
                                  certificadoRecepcion.nombreArchivo
                                );
                                document.body.appendChild(link);
                                link.click();

                                // remove before 5 seconds
                                setTimeout(() => {
                                  window.URL.revokeObjectURL(url);
                                }, 5000);
                              })
                              .catch(err => {
                                Alerta('undefined');
                              });
                          }}
                        >
                          <DescriptionOutlined />
                        </IconButtonComponent>
                      </TooltipComponent>
                    );
                  }
                )
              : console.log(row.original)}
          </>
        );
      },
      enableColumnActions: false,
      enableColumnFilter: false
    }
  ];

  const {
    handleClickEditar,
    handleClickEliminar,
    handleClickDuplicar,
    isEliminando
  } = useHelpersResiduosDeclarados();

  const acciones = [
    {
      key: 'duplicar',
      label: 'Duplicar',
      onClick: handleClickDuplicar,
      icono: ContentCopy,
      type: null
    },
    {
      key: 'editar',
      label: 'Editar',
      icono: Edit,
      onClick: handleClickEditar,
      type: null
    },
    {
      key: 'eliminar',
      label: 'Eliminar',
      icono: Delete,
      onClick: (row, a, b) => {
        const _row = {
          ...row,
          original: {
            ...row.original,
            codigoResiduoDeclarado: row.original.codigo
          }
        };
        setRegistroEliminar(_row);
      },
      type: 'error'
    }
  ];

  useEffect(() => {
    setNumPagina(pagination.pageIndex);
  }, [pagination]);

  return (
    <>
      <Table
        data={residuos || []}
        columns={columnTables}
        manualFiltering
        enableGlobalFilter={false}
        enableTopToolbar={false}
        isLoading={isCargando}
        enableRowActions={true}
        manualPagination
        onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
        state={{ pagination }} //pass the pagination state to the table
        rowCount={pageTable.totalElements}
        muiTablePaginationProps={{
          rowsPerPageOptions: [5],
          showFirstButton: false,
          showLastButton: false
        }}
        renderRowActionMenuItems={
          isLGDown
            ? ({ row, table }) =>
                acciones.map(accion => (
                  <MenuItem
                    key={accion.key}
                    className="p-2.5"
                    onClick={() => accion.onClick(row)}
                  >
                    <accion.icono
                      className={
                        accion.type === 'error'
                          ? 'text-error-dark mr-2'
                          : 'text-uv-primary-0 mr-2'
                      }
                    />
                    <Text className="text-black">{accion.label}</Text>
                  </MenuItem>
                ))
            : null
        }
        actions={
          !isLGDown
            ? ({ row, table }) => (
                <div className="min-w-[140px]">
                  {acciones.map(accion => (
                    <Tooltip
                      arrow
                      placement="left"
                      title={accion.label}
                      key={accion.key}
                    >
                      <IconButtonComponent
                        size="small"
                        onClick={() => accion.onClick(row)}
                        className="mr-2"
                        //disabled={isLoadingLista}
                        type={
                          isCargando
                            ? 'disabled'
                            : accion.type
                            ? 'error'
                            : 'secondary'
                        }
                      >
                        <accion.icono />
                      </IconButtonComponent>
                    </Tooltip>
                  ))}
                </div>
              )
            : null
        }
      />

      <DialogoEliminarResiduo
        isOpen={registroEliminar ? true : false}
        setIsOpen={setRegistroEliminar}
        handleClickEliminar={handleClickEliminar}
        registroEliminar={registroEliminar}
        isEliminando={isEliminando}
        recargarResiduos={recargarResiduos}
      />
      <DialogoCargarCertificado
        isOpen={isUploadOpen}
        setIsOpen={setIsUploadOpen}
        residuo={residuoToAction}
        recargarResiduos={recargarResiduos}
      />
      <DialogoSolicitarCertificado
        isOpen={isSolicitarOpen}
        setIsOpen={setIsSolicitarOpen}
        residuo={residuoToAction}
      />
    </>
  );
};

import { AuthContext } from 'context';
import { useContext } from 'react';

export const VerificarPermisos = ({ nombrePermiso, children }) => {
  const { isTienePermisos } = useContext(AuthContext);

  if (isTienePermisos(nombrePermiso)) {
    return children;
  }

  return null;
};

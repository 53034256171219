import { useCallback, useLayoutEffect, useReducer, useState } from 'react';
import { AuthContext } from './AuthContext';
import { authReducer } from './authReducer';
import { types } from './types/types';
import authentication from 'services/authentication';
import { DICTONARY } from 'const/Dictonary';
import API from 'services/api';
import { CircularProgress } from '@mui/material';
import { Alerta } from 'utils/Alertas';
import { RUTAS } from 'const/Rutas';
import { useHistory } from 'react-router-dom';

const init = () => {
  return {
    logged: undefined,
    token: undefined,
    logeduser: undefined,
    permisos: {},
    tipoNegocio: [],
    perfiles: {
      isSuperAdmin: false,
      isDemandante: false,
      isOferente: false,
      isGestor: false,
      isTransportista: false,
      isAsesor: false,
      isAnalistaTerritorial: false,
      isUsuario: false,
      isAdmin: false,
      isRecicladorDeBase: false
    }
  };
};

export const AuthProvider = ({ children }) => {
  const [authState, dispatch] = useReducer(authReducer, {}, init);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const login = useCallback(user => {
    const formatUsuario = formatUserData(user);
    localStorage.setItem('uvuser', formatUsuario.token);

    const action = {
      type: types.login,
      payload: formatUsuario
    };
    dispatch(action);
  }, []);

  /**
   * Funcion que verifica la sesion del usuario
   *  @param token  :  token del usuario
   *  @param showLoading :  booleano que indica si se muestra el loading lo que por defecto es true (opcional) sirve para manejar la recarga de la pagina
   *  @returns
   * */
  const verifySession = useCallback(
    async (token, showLoading = true) => {
      showLoading && setIsLoading(true);
      await authentication
        .refrescarSesion(token, true)
        .then(result => {
          if (result.data) {
            login(result.data);
          }
        })
        .catch(error => {
          console.error(error);
          localStorage.setItem('tokenExpired', true);
          logout();
          return;
        })
        .finally(() => {
          showLoading && setIsLoading(false);
        });
    },
    [login]
  );

  useLayoutEffect(() => {
    const authInterceptorRequest = API.axios.interceptors.request.use(
      config => {
        config.headers.Authorization =
          authState.logged && authState.token
            ? `Bearer ${authState.token}`
            : config.headers.Authorization;
        return config;
      }
    );

    const authInterceptorResponse = API.axios.interceptors.response.use(
      response => {
        if (response.config.interceptor) {
          if (response.headers.uid) {
            setToken(response.headers.uid);
          }
          if (response.data && response.data.error) {
            const errorCode = response.data.error.codigo || 'UV403-1';
            Alerta(errorCode);
          }
        }

        return response;
      },
      error => {
        try {
          console.log(error, error.response);
          const codigoError = error.response.data.error.codigo;
          // TODO: ojo el servicio esta respondiendo un 403 y no 401
          if (codigoError === 'UV403-2') {
            console.log('credenciales invalidas');
            Alerta(codigoError);
            // se asigna el valor de invalidCredentials a true para que no se vuelva a ejecutar este bloque
            localStorage.setItem('invalidCredentials', true);

            // Limpiar el estado y realizar acciones adicionales
            console.error('LOGOUT_USER_TOKEN EXPIRED');

            return Promise.reject(error);
          }

          // Si el servicio arroja un codigo 403, se valida token y se cierra sesión si es necesario
          if (codigoError === 'UV403-1') {
            Alerta(codigoError);
            // Evitar ejecutar el bloque nuevamente
            localStorage.setItem('tokenExpired', true);
            // Limpiar el estado y realizar acciones adicionales
            console.error('LOGOUT_USER_TOKEN EXPIRED');
            logout();
            history.push(RUTAS.HOME);
            return Promise.reject(error);
          }

          if (error.response.config.interceptor) {
            if (error.response) {
              if (
                error.response.status === 401 &&
                error.response.data === 'Credenciales_inválidas'
              ) {
                console.error('LOGOUT_USER_TOKEN EXPIRED');
              } else if (error.response.status === 404) {
                Alerta(codigoError || 'UV400');
              } else {
                Alerta(codigoError || 'UV400');
              }
            } else {
              Alerta('UV400');
            }
          }
          return Promise.reject(error);
        } catch (error) {
          return Promise.reject(null);
        }
      }
    );

    return () => {
      API.axios.interceptors.request.eject(authInterceptorRequest);
      API.axios.interceptors.response.eject(authInterceptorResponse);
    };
  }, [authState.logged, authState.token]);

  useLayoutEffect(() => {
    const token = localStorage.getItem('uvuser');
    if (token) {
      verifySession(token);
    } else {
      logout();
      setIsLoading(false);
    }
  }, [verifySession]);

  const formatUserData = userData => {
    try {
      const userEstructuraFinal = {
        logeduser: userData,
        permisos: {},
        perfiles: {
          isSuperAdmin: false,
          isDemandante: false,
          isOferente: false,
          isGestor: false,
          isTransportista: false,
          isAsesor: false,
          isAnalistaTerritorial: false,
          isUsuario: false,
          isAdmin: false,
          isRecicladorDeBase: false
        },
        tipoNegocio: [],
        token: userData.token
      };
      if (
        userData.perfiles.find(t => t.nombrePerfil === 'SuperAdministrador')
      ) {
        userEstructuraFinal.perfiles.isSuperAdmin = true;
      }
      if (
        userData.perfiles.find(t => t.nombrePerfil === 'Analista Territorial')
      ) {
        userEstructuraFinal.perfiles.isAnalistaTerritorial = true;
      }
      if (userData.perfiles.find(t => t.nombrePerfil === 'Usuario')) {
        userEstructuraFinal.perfiles.isUsuario = true;
      }
      if (
        userData.perfiles.find(t => t.nombrePerfil.startsWith('Administrador'))
      ) {
        userEstructuraFinal.perfiles.isAdmin = true;
      }

      /**
       * Roles
       *  Generador             : 1
       *  Receptor              : 2
       *  Transportista         : 3
       *  Gestor                : 4
       *  Asesor                : 5
       *  Reciclador de Base    : 6
       */
      if (
        userData.sucursal?.empresa.roles.find(
          t => t.codigoRol === DICTONARY.ROL.GENERADOR.CODIGO
        )
      ) {
        userEstructuraFinal.perfiles.isOferente = true;
        userEstructuraFinal.tipoNegocio.push(DICTONARY.ROL.GENERADOR.NOMBRE);
      }
      if (
        userData.sucursal?.empresa.roles.find(
          t => t.codigoRol === DICTONARY.ROL.RECEPTOR.CODIGO
        )
      ) {
        userEstructuraFinal.perfiles.isDemandante = true;
        userEstructuraFinal.tipoNegocio.push(DICTONARY.ROL.RECEPTOR.NOMBRE);
      }
      if (
        userData.sucursal?.empresa.roles.find(
          t => t.codigoRol === DICTONARY.ROL.TRANSPORTISTA.CODIGO
        )
      ) {
        userEstructuraFinal.perfiles.isTransportista = true;
        userEstructuraFinal.tipoNegocio.push(
          DICTONARY.ROL.TRANSPORTISTA.NOMBRE
        );
      }
      if (
        userData.sucursal?.empresa.roles.find(
          t => t.codigoRol === DICTONARY.ROL.GESTOR.CODIGO
        )
      ) {
        userEstructuraFinal.perfiles.isGestor = true;
        userEstructuraFinal.tipoNegocio.push(DICTONARY.ROL.GESTOR.NOMBRE);
      }
      if (
        userData.sucursal?.empresa.roles.find(
          t => t.codigoRol === DICTONARY.ROL.ASESOR.CODIGO
        )
      ) {
        userEstructuraFinal.perfiles.isAsesor = true;
        userEstructuraFinal.tipoNegocio.push(DICTONARY.ROL.ASESOR.NOMBRE);
      }
      if (
        userData.sucursal?.empresa.roles.find(
          t => t.codigoRol === DICTONARY.ROL.RECICLADORDEBASE.CODIGO
        )
      ) {
        userEstructuraFinal.perfiles.isRecicladorDeBase = true;
        userEstructuraFinal.tipoNegocio.push(
          DICTONARY.ROL.RECICLADORDEBASE.NOMBRE
        );
      }

      for (const item of userData.permisos) {
        userEstructuraFinal.permisos[item.codigoPermiso] = {
          codigoPermiso: item.codigoPermiso,
          nombrePermiso: item.nombrePermiso,
          descripcionPermiso: item.descripcionPermiso
        };
      }
      return userEstructuraFinal;
    } catch (error) {
      console.warn('ERROR_EN_ESTRUCTURACION_DE_USUARIO', error);
    }
  };

  const setToken = token => {
    const action = {
      type: types.setToken,
      payload: token
    };
    dispatch(action);
  };

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('logeduser');
    localStorage.removeItem('token');
    localStorage.removeItem('uvuser');
    const action = { type: types.logout };
    dispatch(action);
  };

  const isTienePermisos = nombrePermiso => {
    if (!authState.permisos) {
      return false;
    }

    const tienePermisos = authState.permisos[nombrePermiso] || null;
    return tienePermisos ? true : false;
  };

  const refrescarSesion = async token => {
    await authentication
      .refrescarSesion(token, true)
      .then(result => {
        if (result.data) {
          login(result.data);
        }
      })
      .catch(error => {
        console.error(error);
        logout();
        return;
      });
  };

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        login: login,
        logout: logout,
        refrescarSesion: refrescarSesion,
        isTienePermisos
      }}
    >
      {isLoading ? (
        <div className="flex h-screen justify-center items-center">
          <CircularProgress className="text-uv-primary-0" />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

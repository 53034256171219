import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import { Cifra } from './Cifra';
import IconComponent from 'components/design-system/icon/Icon';
import { BloquesIndicadoresSkeleton } from './BloquesIndicadoresSkeleton';

export const BloquesIndicadores = ({
  indicadores,
  isLoading,
  isSuperAdmin
}) => {
  if (isLoading) {
    return <BloquesIndicadoresSkeleton />;
  }

  if (isSuperAdmin && indicadores.state === 'IDLE') {
    return <></>;
  }

  return (
    <div>
      <div className="mb-6">
        <Heading type="h3" className="font-bold text-uv-primary-0">
          Transacciones en Marketplace
        </Heading>
        <div className="flex shadow-md flex-col md:flex-row">
          <div className="w-full md:w-1/4 bg-uv-primary-0 rounded-tl rounded-bl">
            <div className="px-6 py-4">
              {indicadores.marketplace.aside.map(indicador => (
                <div className="mb-8 mt-2">
                  <Text type="label" className="text-white mb-2">
                    {indicador.titulo ? indicador.titulo : ''}
                  </Text>
                  <div className="flex items-center">
                    {indicador.icon ? indicador.icon : ''}
                    <div className="pl-3">
                      <Heading type="H2" className="text-white my-0">
                        <Cifra valor={indicador.valor ? indicador.valor : ''} />
                      </Heading>
                      <Text className=" text-white leading-5 font-normal">
                        {indicador.descripcion ? indicador.descripcion : ''}
                      </Text>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex-grow w-auto px-6 py-4 bg-white rounded-tr rounded-br">
            <Heading type="h3" className="text-uv-secondary-0 font-bold mb-0">
              Indicadores de impacto ambiental
            </Heading>
            <div className="grid grid-cols-1 sm:grid-cols-2 mt-2">
              {indicadores.marketplace.principal.map((indicador, index) => (
                <IndicadorItem
                  key={index}
                  title={indicador.titulo ? indicador.titulo : ''}
                  value={indicador.valor ? indicador.valor : ''}
                  description={
                    indicador.descripcion ? indicador.descripcion : ''
                  }
                  icon={indicador.icon ? indicador.icon : ''}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="mb-6">
        <Heading type="h3" className="font-bold text-uv-primary-0">
          Declaración en módulo Gestiona
        </Heading>
        <div className="flex shadow-md flex-col md:flex-row">
          <div className="w-full md:w-1/4 bg-uv-primary-0 rounded-tl rounded-bl">
            <div className="px-6 py-4">
              {indicadores.gestiona.aside.map(indicador => (
                <div className="mb-8 mt-2">
                  <Text type="label" className="text-white mb-2">
                    {indicador.titulo ? indicador.titulo : ''}
                  </Text>
                  <div className="flex items-center">
                    {indicador.icon ? indicador.icon : ''}
                    <div className="pl-3">
                      <Heading type="H2" className="text-white my-0">
                        <Cifra valor={indicador.valor ? indicador.valor : ''} />
                      </Heading>
                      <Text className=" text-white leading-5 font-normal">
                        {indicador.descripcion ? indicador.descripcion : ''}
                      </Text>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex-grow w-auto px-6 py-4 bg-white rounded-tr rounded-br">
            <Heading type="h3" className="text-uv-secondary-0 font-bold mb-0">
              Indicadores de impacto ambiental
            </Heading>
            <div className="grid grid-cols-1 sm:grid-cols-2 mt-2">
              {indicadores.gestiona.principal.map((indicador, index) => (
                <IndicadorItem
                  key={index}
                  title={indicador.titulo ? indicador.titulo : ''}
                  value={indicador.valor ? indicador.valor : ''}
                  description={
                    indicador.descripcion ? indicador.descripcion : ''
                  }
                  icon={indicador.icon ? indicador.icon : ''}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const IndicadorItem = ({ title, value, description, icon }) => {
  return (
    <div className="w-full flex-wrap py-3">
      <Text type="label" className="text-uv-primary-0 mb-2 pr-3">
        {title}
      </Text>
      <div className="flex items-center">
        <IconComponent
          color="primary"
          className="bg-uv-primary-90 rounded-full flex w-14 h-14 items-center justify-center p-2.5"
        >
          {icon}
        </IconComponent>

        <div className="pl-3">
          <Heading type="H2" className="font-bold my-0">
            <Cifra valor={value} />
          </Heading>
          <Text className="text-black leading-5 font-normal">
            {description}
          </Text>
        </div>
      </div>
    </div>
  );
};

import { useState, useEffect, useRef, useContext } from 'react';
import io from 'socket.io-client';
import getEnv from '../../helpers/getEnv';
import { AuthContext } from 'context';

const ENDPOINT = getEnv('REACT_APP_CHAT_URL') || '//45.236.128.129:5000';

export default function useChatNotification() {
  const { logeduser } = useContext(AuthContext);
  const [user, setUser] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [socket, setSocket] = useState(null);
  const match = useRef(null);

  const setMatch = m => {
    match.current = m;
  };

  useEffect(() => {
    setUser(logeduser);
  }, [logeduser]);

  useEffect(() => {
    if (!user) {
      socket && socket.close();
      return;
    }

    /* socketNewConnection(); */
  }, [user]);

  const socketNewConnection = () => {
    const socket = io(ENDPOINT, { reconnection: false });

    setSocket(socket);
  };

  useEffect(() => {
    if (socket === null) return;

    socketSuscribers();
    socket.emit('suscribeNotifications', getSocketUser(), error => {
      if (error) {
        console.error('error desde el socket');
        console.error(error);
      }
    });

    return () => {
      socket.removeAllListeners('notification');
      socket.removeAllListeners('connect_error');
      socket.removeAllListeners('disconnect');

      socket.close();
    };
  }, [socket]);

  const socketSuscribers = () => {
    socket.on('notification', notification => {
      if (
        match.current &&
        match.current.path === '/chat/:roomid' &&
        notification.room == match.current.params.roomid
      ) {
        return;
      }

      setNotifications(notif => {
        return [...notif, notification];
      });
    });

    socket.on('connect_error', () => {
      setTimeout(() => {
        socketNewConnection();
      }, 2000);
    });

    socket.on('disconnect', () => {
      setTimeout(() => {
        socketNewConnection();
      }, 2000);
    });
  };

  const getSocketUser = () => {
    const { nombreUsuario, apellidoPaterno, codigoUsuario } = user;
    const name = nombreUsuario + ' ' + apellidoPaterno;
    const userId = codigoUsuario;

    return { name, room: 'notifications', userId };
  };

  const markAllRead = () => {
    setNotifications(notf => {
      return notf.map(n => ({ ...n, read: true }));
    });
  };
  const getLast = () => {
    return notifications[notifications.length - 1] || null;
  };
  const getNumberNotRead = () => {
    return notifications.filter(n => !n.read).length;
  };

  const markRead = roomId => {
    setNotifications(notf => {
      return notf.map(n => ({ ...n, read: n.room == roomId ? true : n.read }));
    });
  };

  return {
    notifications,
    markAllRead,
    getLast,
    getNumberNotRead,
    setMatch,
    markRead
  };
}

import React, { useContext, useState } from 'react';
import Heading from 'components/design-system/Heading/Heading';
import {
  Paginacion,
  ServicioListaTransacciones
} from './../../Transaccion/hooks';
import { FiltroForm } from './FiltroForm/FiltroForm';
import { MapaTransacciones } from './Mapa/MapaTransacciones';
import { TablaTransacciones } from './Tabla/TablaTransacciones';
import { getCoordenadasMapa } from 'utils';
import { AuthContext } from 'context';

const Transacciones = () => {
  const { logeduser } = useContext(AuthContext);
  const [isLoading, setisLoading] = useState(true);
  const [filtros, setFiltros] = useState();
  const [registroTablaSeleccionado, setRegistroTablaSeleccionado] = useState();
  const [coordenadasCentro, setCoordinadasCentro] = useState(
    getCoordenadasMapa(logeduser)
  );
  const { setData, total, setTotal, pagination, setPagination } =
    Paginacion(setisLoading);
  const { listaTransacciones, handleListaTransaccion } =
    ServicioListaTransacciones(setData, setTotal, setisLoading);

  return (
    <div className="w-full">
      <div className="bg-white shadow-md py-5">
        <div className="px-4 md:container md:mx-auto">
          <div className="grid grid-cols-12">
            <div className="col-span-12">
              <Heading type="h2" className="text-uv-primary-0">
                Registro de transacciones
              </Heading>
            </div>
          </div>
          <FiltroForm isLoading={isLoading} setFiltros={setFiltros} />
        </div>
      </div>
      <div className="px-4 md:mx-auto py-6">
        <div className="grid grid-cols-12 gap-4">
          <MapaTransacciones
            listaTransacciones={listaTransacciones}
            registroTablaSeleccionado={registroTablaSeleccionado}
            coordenadasCentro={coordenadasCentro}
            isLoading={isLoading}
          />
          <TablaTransacciones
            listaTransacciones={listaTransacciones}
            pagination={pagination}
            setPagination={setPagination}
            handleListaTransaccion={handleListaTransaccion}
            setData={setData}
            setTotal={setTotal}
            total={total}
            isLoading={isLoading}
            registroTablaSeleccionado={registroTablaSeleccionado}
            setRegistroTablaSeleccionado={setRegistroTablaSeleccionado}
            setCoordinadasCentro={setCoordinadasCentro}
            filtros={filtros}
          />
        </div>
      </div>
    </div>
  );
};

export default Transacciones;

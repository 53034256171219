export const PERMISOS = {
  busquedaResiduoRecurso: 1,
  matchAutomaticoResiduoRecurso: 2,
  busquedaEmpresa: 3,
  matchEmpresa: 4,
  busquedaEmpresaExtendido: 5,
  transacciones: 6,
  resumenTransaccion: 7,
  iniciarTransaccion: 8,
  cerrarTransaccion: 9,
  solicitarReunion: 10,
  salasReunion: 11,
  recursoAdquirido: 12,
  crearRecursoAdquirido: 13,
  editarRecursoAdquirido: 14,
  eliminarRecursoAdquirido: 15,
  residuoDeclarado: 16,
  crearResiduoDeclarado: 17,
  editarResiduoDeclarado: 18,
  eliminarResiduoDeclarado: 19,
  calculadoraIndicadores: 20,
  indicadoresOnline: 21,
  descargarReportes: 22,
  buscarMaterialInformacion: 23,
  cargarMaterialInformacion: 24,
  negocio: 25,
  editarNegocio: 26,
  sucursal: 27,
  crearSucursal: 28,
  editarSucursal: 29,
  usuarios: 30,
  invitarUsuarios: 31,
  editarUsuario: 32,
  eliminarUsuario: 33,
  residuoRecurso: 34,
  ofrecerResiduo: 35,
  requerirResiduo: 36,
  configurarIndicadores: 37,
  registroActividad: 38,
  cambiarContrasena: 39,
  editarCuenta: 40,
  registrarNegocio: 41,
  lineaBase: 42,
  potencialSinergia: 43,
  cargaBaseDatos: 44,
  empresaAT: 45,
  crearEmpresaAT: 46,
  editarEmpresaAT: 47,
  eliminarEmpresaAT: 48,
  residuoAT: 49,
  crearResiduoAT: 50,
  editarResiduoAT: 51,
  eliminarResiduoAT: 52,
  acreditacionesCertificaciones: 53,
  totalGestionar: 54,
  totalGestionado: 55,
  totalAsesorado: 56,
  totalProyectadoTransportar: 57,
  totalTransportadas: 58,
  kmRecorridos: 59,
  recibidoComprado: 60,
  residuoGenerado: 61,
  residuoProyectado: 62,
  procesoGestion: 63,
  transaccionesAT: 64,
  eliminarNegocio: 65,
  editarMaterialInformacion: 66,
  eliminarResiduoRecurso: 69,
  eliminarMaterialInformacion: 70,
  crearUsuario: 71,
  editarResiduoOferta: 72,
  eliminarResiduoOferta: 73,
  editarResiduoDemanda: 74,
  eliminarResiduoDemanda: 75,
  duplicarResiduoOferta: 77,
  duplicarResiduoDemanda: 78,
  duplicarResiduoDeclarado: 79,
  duplicarRecursoAdquirido: 80,
  editarCuentaRegion: 81,
  editarCuentaEmpresa: 82,
  editarCuentaSucursal: 83,
  crearPerfil: 84,
  editarPerfil: 85,
  eliminarPerfil: 86,
  areaDeTrabajo: 87,
  autorizacionSanitariaTransporte: 88,
  autorizacionSanitariaAcopio: 89,
  registroNacionalRDB: 90,
  clientes: 91,
  sucursalOrigenEmpresa: 92,
  tipoResiduo: 93,
  nombreResiduo: 94,
  recursos: 95,
  recursoReemplazo: 96,
  descripcionResiduo: 97,
  sucursalOrigen: 98,
  nombreLER: 99,
  precioReferencial: 100,
  frecuencia: 101,
  fechaDisponibilidad: 102,
  cantidad: 103,
  unidadMedida: 104,
  formatoEntrega: 105,
  levantamientoDatos: 106,
  archivosResiduo: 107,
  declararTransaccion: 108,
  sucursalOrigenResiduoDeclarado: 109,
  empresaGeneradoraResiduoDeclarado: 110,

  dashboard: 113,
  dashboardDatosNegocio: 114,

  accesoBusquedaResiduos: 117,
  accesoResiduoDeclarado: 118,
  accesoResiduoOfertado: 119,
  accesoResiduoRequerido: 120
};

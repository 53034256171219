import React, { useContext, useEffect, useState } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout } from 'components';
import { Main as MainLayout } from 'layouts';
import { OlvidoContrasena as OlvidoContrasenaView } from 'views';
import { RutasPublicas } from './RutasPublicas';
import { RutasPrivadas } from './RutasPrivadas';
import { AuthContext } from 'context';
import { PERMISOS } from 'const/Permisos';
import { Alerta } from 'utils/Alertas';
import { RUTAS } from 'const/Rutas';

const Routes = () => {
  const { logged, logeduser } = useContext(AuthContext);
  const [redirectCambioContrasena, setRedirectCambioContrasena] =
    useState(false);

  useEffect(() => {
    const tokenExpired = localStorage.getItem('tokenExpired');
    const invalidCredentials = localStorage.getItem('invalidCredentials');

    if (logged) {
      if (tokenExpired) {
        localStorage.removeItem('tokenExpired');
      }

      if (invalidCredentials) {
        localStorage.removeItem('invalidCredentials');
      }
    } else {
      if (tokenExpired) {
        Alerta('UV403-1');
        localStorage.removeItem('tokenExpired');
      }

      if (invalidCredentials) {
        Alerta('UV403-2');
        localStorage.removeItem('invalidCredentials');
      }
    }
  }, [logged]);

  useEffect(() => {
    if (!logeduser) {
      return;
    }
    if (
      logeduser.passwordTemporal &&
      window.location.pathname !== RUTAS.CAMBIAR_CONTRASENA
    ) {
      setRedirectCambioContrasena(true);
    }
  }, [logged]);

  if (logged && redirectCambioContrasena) {
    return (
      <Switch>
        <RouteWithLayout
          component={OlvidoContrasenaView}
          exact
          store={logeduser}
          layout={MainLayout}
          nombrePermiso={PERMISOS.cambiarContrasena}
          path={RUTAS.CAMBIAR_CONTRASENA}
        />
        <Redirect to={RUTAS.CAMBIAR_CONTRASENA} />
      </Switch>
    );
  } else {
    return <>{logged ? <RutasPrivadas /> : <RutasPublicas />}</>;
  }
};

export default Routes;

import React, { useState, useEffect, useContext } from 'react';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import iconHomeDemandas from 'assets/images/home/homedemandas.svg';
import iconHomeOfertas from 'assets/images/home/homeofertas.svg';
import { DICTONARY } from 'const/Dictonary';
import { Link } from 'react-router-dom';
import { PlayCircleOutline, NavigateNext } from '@mui/icons-material';
import ButtonComponent from 'components/design-system/Button/Button';
import imgHomeUser from 'assets/images/home/grafica-home-user.svg';
import { AuthContext } from 'context';
import { formatoANumeroIngles } from 'helpers/formateoNumeros';
import useServices from './hooks/useServices';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import jwt from 'jwt-decode';
import IconComponent from 'components/design-system/icon/Icon';
import { Cifra } from 'views/Indicadores/Reporte/Cifra';
import { ICON_NUMERO_TRANSACCIONES } from 'icons/ReporteIndicadores/IconNumeroTransacciones';
import { ICON_RESIDUO_VALORIZADO } from 'icons/ReporteIndicadores/IconResiduoValorizado';
import { ICON_CAMBIO_CLIMATICO } from 'icons/ReporteIndicadores/IconCambioClimatico';
import { ICON_MATERIAL_PARTICULADO } from 'icons/ReporteIndicadores/IconMaterialParticulado';
import { ICON_DEMANDA_ACUMULADA } from 'icons/ReporteIndicadores/IconDemandaAcumulada';
import { ICON_USUARIOS } from 'icons/ReporteIndicadores/IconUsuarios';
import PDF_FILE from './../../assets/anexo_tecnico_indicadores.pdf';
import { CuadroIndicadoresSkeleton } from './componentes/CuadroIndicadoresSkeleton';
import { RUTAS } from 'const/Rutas';
// decifrando jwt
const decifrarToken = token => {
  const decifrado = jwt(token);
  return decifrado;
};

const HomeSitio = props => {
  const { logged, logeduser } = useContext(AuthContext);
  const location = useLocation();
  const history = useHistory();

  const [verificaUsuarioNoRegistrado, setVerificaUsuarioNoRegistrado] =
    useState(localStorage.getItem('verificaUsuario') || false);

  // Si el usuario no está logueado y recibe un token, redirigir a la página de registro
  if (
    location.search !== '' &&
    !logged &&
    location.search.includes('token') &&
    verificaUsuarioNoRegistrado
  ) {
    const tokenCompleto = location.search;
    const token = location.search.split('=')[1];
    const tokenDecifrado = decifrarToken(tokenCompleto);
    const { correoElectronico, codigoSucursal } = tokenDecifrado;
    history.push(`${RUTAS.REGISTRARSE}?token=${token}`, {
      token: token,
      correoElectronico: correoElectronico,
      codigoSucursal: codigoSucursal
    });
  }

  const [indicadores, setIndicadores] = useState({
    marketplace: {
      aside: [],
      principal: []
    },
    gestiona: {
      aside: [],
      principal: []
    },
    usuarios: {},
    state: 'IDLE'
  });

  // Refactorización de carga de indicadores
  const {
    cargaUsuarios,
    cargaTransacciones,
    cargaResiduos,
    cargaReduccion,
    cargaResumenIndicadores,
    cargarDeclaraciones,
    isLoadingUsuarios,
    isLoadingTransacciones,
    isLoadingResiduos,
    isLoadingReduccion,
    isLoadingResumen,
    isLoadingDeclarado,
    dataUsuario,
    dataTransaccion,
    dataResiduo,
    dataReduccion,
    dataResumen,
    dataDeclarado
  } = useServices();

  useEffect(() => {
    cargaUsuarios();
    cargaTransacciones();
    cargaResiduos();
    cargaReduccion();
    cargaResumenIndicadores();
    cargarDeclaraciones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIndicadores({
      marketplace: {
        aside: [
          {
            titulo: 'Nº TRANSACCIONES',
            descripcion: 'Transacciones',
            icon: <ICON_NUMERO_TRANSACCIONES />,
            isLoading: isLoadingTransacciones,
            valor: dataTransaccion?.transaccion
              ? formatoANumeroIngles(dataTransaccion.transaccion.toString())
              : ''
          },
          {
            titulo: 'RESIDUOS VALORIZADOS',
            descripcion: 'ton',
            icon: <ICON_RESIDUO_VALORIZADO />,
            isLoading: isLoadingResiduos,
            valor: dataResiduo?.residuo?.valorizado
              ? formatoANumeroIngles(dataResiduo.residuo.valorizado.toString())
              : ''
          }
        ],
        principal: [
          {
            titulo: 'CAMBIO CLIMÁTICO',
            descripcion: 'kg CO2eq',
            icon: <ICON_CAMBIO_CLIMATICO />,
            isLoading: isLoadingReduccion,
            valor: dataReduccion?.reduccion?.marketplace
              .reduccionCambioClimatico
              ? formatoANumeroIngles(
                  dataReduccion.reduccion.marketplace.reduccionCambioClimatico.toString()
                )
              : ''
          },
          {
            titulo: 'AGOTAMIENTO DE RECURSO FÓSIL',
            descripcion: 'kg petróleo eq',
            icon: <ICON_CAMBIO_CLIMATICO />,
            isLoading: isLoadingReduccion,
            valor: dataReduccion?.reduccion?.marketplace.reduccionRecursoFosil
              ? formatoANumeroIngles(
                  dataReduccion.reduccion.marketplace.reduccionRecursoFosil.toString()
                )
              : ''
          },
          {
            titulo: 'FORMACIÓN DE MATERIAL PARTICULADO',
            descripcion: 'kgmp 2,5eq',
            icon: <ICON_MATERIAL_PARTICULADO />,
            isLoading: isLoadingReduccion,
            valor: dataReduccion?.reduccion?.marketplace
              .reduccionMaterialParticulado
              ? formatoANumeroIngles(
                  dataReduccion.reduccion.marketplace.reduccionMaterialParticulado.toString()
                )
              : ''
          },
          {
            titulo: 'DEMANDA ACUMULADA DE ENERGÍA',
            descripcion: 'MJ eq',
            icon: <ICON_DEMANDA_ACUMULADA />,
            isLoading: isLoadingReduccion,
            valor: dataReduccion?.reduccion?.marketplace
              .reduccionDemandaAcumulada
              ? formatoANumeroIngles(
                  dataReduccion.reduccion.marketplace.reduccionDemandaAcumulada.toString()
                )
              : ''
          }
        ]
      },
      gestiona: {
        aside: [
          {
            titulo: 'Nº DECLARACIONES',
            descripcion: 'declaraciones',
            icon: <ICON_NUMERO_TRANSACCIONES />,
            isLoading: isLoadingDeclarado,
            valor: dataDeclarado?.declarado
              ? formatoANumeroIngles(dataDeclarado.declarado.toString())
              : ''
          },
          {
            titulo: 'RESIDUOS DECLARADOS',
            descripcion: 'ton',
            icon: <ICON_RESIDUO_VALORIZADO />,
            isLoading: isLoadingResiduos,
            valor: dataResiduo?.residuo?.declarado
              ? formatoANumeroIngles(dataResiduo.residuo.declarado.toString())
              : ''
          }
        ],
        principal: [
          {
            titulo: 'CAMBIO CLIMÁTICO',
            descripcion: 'kg CO2eq',
            icon: <ICON_CAMBIO_CLIMATICO />,
            isLoading: isLoadingReduccion,
            valor: dataReduccion?.reduccion?.gestiona.reduccionCambioClimatico
              ? formatoANumeroIngles(
                  dataReduccion.reduccion.gestiona.reduccionCambioClimatico.toString()
                )
              : ''
          },
          {
            titulo: 'AGOTAMIENTO DE RECURSO FÓSIL',
            descripcion: 'kg petróleo eq',
            icon: <ICON_CAMBIO_CLIMATICO />,
            isLoading: isLoadingReduccion,
            valor: dataReduccion?.reduccion?.gestiona.reduccionRecursoFosil
              ? formatoANumeroIngles(
                  dataReduccion.reduccion.gestiona.reduccionRecursoFosil.toString()
                )
              : ''
          },
          {
            titulo: 'FORMACIÓN DE MATERIAL PARTICULADO',
            descripcion: 'kgmp 2,5eq',
            icon: <ICON_MATERIAL_PARTICULADO />,
            isLoading: isLoadingReduccion,
            valor: dataReduccion?.reduccion?.gestiona
              .reduccionMaterialParticulado
              ? formatoANumeroIngles(
                  dataReduccion.reduccion.gestiona.reduccionMaterialParticulado.toString()
                )
              : ''
          },
          {
            titulo: 'DEMANDA ACUMULADA DE ENERGÍA',
            descripcion: 'MJ eq',
            icon: <ICON_DEMANDA_ACUMULADA />,
            isLoading: isLoadingReduccion,
            valor: dataReduccion?.reduccion?.gestiona.reduccionDemandaAcumulada
              ? formatoANumeroIngles(
                  dataReduccion.reduccion.gestiona.reduccionDemandaAcumulada.toString()
                )
              : ''
          }
        ]
      },
      usuarios: {
        titulo: 'USUARIOS REGISTRADOS',
        descripcion: 'usuarios',
        icon: <ICON_USUARIOS />,
        isLoading: isLoadingUsuarios,
        valor: dataUsuario.usuario
          ? formatoANumeroIngles(dataUsuario.usuario.toString())
          : ''
      }
    });

    // Almacenar indicadores en localStorage
    localStorage.setItem(
      'indicadores',
      JSON.stringify({
        usuario: dataUsuario?.usuario,
        transaccion: dataTransaccion?.transaccion,
        residuo: dataResiduo?.residuo,
        reduccionCambioClimatico:
          dataReduccion?.reduccion?.reduccionCambioClimatico,
        reduccionRecursoFosil: dataReduccion?.reduccion?.reduccionRecursoFosil,
        reduccionMaterialParticulado:
          dataReduccion?.reduccion?.reduccionMaterialParticulado,
        reduccionDemandaAcumulada:
          dataReduccion?.reduccion?.reduccionDemandaAcumulada,
        demanda: dataResumen?.resumen?.demanda,
        oferta: dataResumen?.resumen?.oferta,
        residuoDeclarado: dataResiduo?.residuo?.declarado
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingUsuarios,
    isLoadingTransacciones,
    isLoadingResiduos,
    isLoadingReduccion,
    isLoadingResumen,
    isLoadingDeclarado
  ]);

  useEffect(() => {
    // eliminar localStorage de indicadores cada 2 horas
    const interval = setInterval(() => {
      localStorage.removeItem('indicadores');
    }, 7200000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full bg-gradient-to-b from-[#EFF5F4] to-white mb-3 pt-3">
      <div className="px-4 md:container md:mx-auto">
        <div className="bg-white shadow-md rounded mb-5 mt-4">
          <div className="grid grid-cols-12 items-center">
            <div className="col-span-12 md:col-span-6 lg:col-span-4 p-5 md:py-10 md:px-12 bg-uv-primary-0">
              {!logged ? (
                <Heading type="h1" className="text-white mb-6 font-light">
                  Únete a la
                  <br />
                  <b className="font-semibold">Industria Circular</b>
                </Heading>
              ) : (
                <>
                  <Heading type="h1" className="text-white mb-6 font-light">
                    ¡Bienvenido/a
                    <br />
                    {logeduser.nombreUsuario ||
                      'Nombre de usuario no detectado'}
                    <br />a la plataforma de{' '}
                    <b className="font-semibold">Industria Circular</b>!
                  </Heading>
                  <Text
                    className="text-white opacity-80 tracking-wider font-normal"
                    size="L"
                  >
                    El Market Place permite conectar oportunidades de negocio
                    entre organizaciones para la reutilización y reciclado de
                    materias y residuos.
                  </Text>
                </>
              )}

              {!logged ? (
                <>
                  <div>
                    <Link to={RUTAS.REGISTRARSE}>
                      <ButtonComponent type="primary" className="mb-4">
                        Registro <NavigateNext />
                      </ButtonComponent>
                    </Link>
                  </div>
                  <div>
                    <a
                      href={DICTONARY.URL.PORTAL_DIFUSION_VIDEOS}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ButtonComponent
                        type="ghost"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        className="text-white hover:text-uv-primary-0 !bg-uv-primary-20"
                      >
                        Ver tutoriales{' '}
                        <PlayCircleOutline style={{ marginLeft: '10px' }} />
                      </ButtonComponent>
                    </a>
                  </div>
                </>
              ) : null}
            </div>
            <div
              className="hidden md:block md:col-span-6 lg:col-span-8 h-full bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${imgHomeUser})` }}
            ></div>
          </div>
        </div>

        <div className="mb-6">
          <Heading type="h3" className="font-bold text-uv-primary-0">
            Transacciones en Marketplace
          </Heading>
          <div className="flex shadow-md flex-col md:flex-row mb-6">
            <div className="w-full md:w-1/4 bg-uv-primary-0 rounded-tl rounded-bl">
              <div className="px-6 py-4">
                {indicadores.marketplace.aside.map((indicador, index) => (
                  <div className="mb-8 mt-2" key={index}>
                    {indicador.isLoading ? (
                      <CuadroIndicadoresSkeleton />
                    ) : (
                      <>
                        <Text type="label" className="text-white mb-2">
                          {indicador.titulo ? indicador.titulo : ''}
                        </Text>
                        <div className="flex items-center">
                          {indicador.icon ? indicador.icon : ''}
                          <div className="pl-3">
                            <Heading type="H2" className="text-white my-0">
                              <Cifra
                                valor={indicador.valor ? indicador.valor : ''}
                              />
                            </Heading>
                            <Text className=" text-white leading-5 font-normal">
                              {indicador.descripcion
                                ? indicador.descripcion
                                : ''}
                            </Text>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex-grow w-auto px-6 py-4 bg-white rounded-tr rounded-br">
              <Heading type="h3" className="text-uv-secondary-0 font-bold mb-0">
                Indicadores de impacto ambiental
              </Heading>
              <div className="grid grid-cols-1 sm:grid-cols-2 mt-2 gap-5">
                {indicadores.marketplace.principal.map((indicador, index) => (
                  <React.Fragment key={index}>
                    {indicador.isLoading ? (
                      <div className="w-full flex-wrap py-3">
                        <CuadroIndicadoresSkeleton />
                      </div>
                    ) : (
                      <IndicadorItem
                        title={indicador.titulo ? indicador.titulo : ''}
                        value={indicador.valor ? indicador.valor : ''}
                        description={
                          indicador.descripcion ? indicador.descripcion : ''
                        }
                        icon={indicador.icon ? indicador.icon : ''}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>

          <Heading type="h3" className="font-bold text-uv-primary-0">
            Declaración en módulo Gestiona
          </Heading>
          <div className="flex shadow-md flex-col md:flex-row">
            <div className="w-full md:w-1/4 bg-uv-primary-0 rounded-tl rounded-bl">
              <div className="px-6 py-4">
                {indicadores.gestiona.aside.map((indicador, index) => (
                  <div className="mb-8 mt-2" key={index}>
                    {indicador.isLoading ? (
                      <CuadroIndicadoresSkeleton />
                    ) : (
                      <>
                        <Text type="label" className="text-white mb-2">
                          {indicador.titulo ? indicador.titulo : ''}
                        </Text>
                        <div className="flex items-center">
                          {indicador.icon ? indicador.icon : ''}
                          <div className="pl-3">
                            <Heading type="H2" className="text-white my-0">
                              <Cifra
                                valor={indicador.valor ? indicador.valor : ''}
                              />
                            </Heading>
                            <Text className=" text-white leading-5 font-normal">
                              {indicador.descripcion
                                ? indicador.descripcion
                                : ''}
                            </Text>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex-grow w-auto px-6 py-4 bg-white rounded-tr rounded-br">
              <Heading type="h3" className="text-uv-secondary-0 font-bold mb-0">
                Indicadores de impacto ambiental
              </Heading>
              <div className="grid grid-cols-1 sm:grid-cols-2 mt-2">
                {indicadores.gestiona.principal.map((indicador, index) => (
                  <React.Fragment key={index}>
                    {indicador.isLoading ? (
                      <div className="w-full flex-wrap py-3">
                        <CuadroIndicadoresSkeleton />
                      </div>
                    ) : (
                      <IndicadorItem
                        title={indicador.titulo ? indicador.titulo : ''}
                        value={indicador.valor ? indicador.valor : ''}
                        description={
                          indicador.descripcion ? indicador.descripcion : ''
                        }
                        icon={indicador.icon ? indicador.icon : ''}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>

        {isLoadingResumen ? (
          <CuadroIndicadoresSkeleton />
        ) : (
          <div className="mb-6">
            <div className="flex shadow-md flex-col md:flex-row">
              <div className="grid grid-cols-12 shadow-md w-full">
                <div className="col-span-12 md:col-span-3 bg-uv-primary-0 rounded-tl rounded-bl px-6 py-6 mb-8 h-full">
                  <Text type="label" className="text-white mb-2">
                    {indicadores.usuarios.titulo
                      ? indicadores.usuarios.titulo
                      : ''}
                  </Text>
                  <div className="flex items-center">
                    <div className="w-14 h-14 rounded-full bg-uv-primary-10">
                      {indicadores.usuarios.icon
                        ? indicadores.usuarios.icon
                        : ''}
                    </div>
                    <div className="pl-3">
                      <Heading type="H2" className="text-white my-0">
                        <Cifra
                          valor={
                            indicadores.usuarios.valor
                              ? indicadores.usuarios.valor
                              : ''
                          }
                        />
                      </Heading>
                      <Text className=" text-white leading-5 font-normal">
                        {indicadores.usuarios.descripcion
                          ? indicadores.usuarios.descripcion
                          : ''}
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="col-span-12 md:col-span-9 px-6 py-4 bg-white rounded-tr rounded-br">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12 lg:col-span-6">
                      <div className="flex items-center">
                        <img
                          src={iconHomeOfertas}
                          alt="Ofertas recientes"
                          className="w-10"
                        />
                        <Heading
                          type="h3"
                          className="text-uv-secondary-0 font-bold ml-1.5"
                        >
                          Residuos ofertados más recientes
                        </Heading>
                      </div>
                      <ul className="pl-4">
                        {dataResumen?.resumen?.oferta?.map((oferta, i) => (
                          <li key={i} className="relative my-3 pl-4">
                            <div className="w-1.5 h-1.5 rounded-full bg-uv-secondary-0 absolute left-0 top-2"></div>
                            <Text className="text-sm font-normal">
                              {oferta}
                            </Text>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="col-span-12 lg:col-span-6">
                      <div className="flex items-center">
                        <img
                          src={iconHomeDemandas}
                          alt="Ofertas recientes"
                          className="w-10"
                        />
                        <Heading
                          type="h3"
                          className="text-uv-secondary-0 font-bold ml-1.5"
                        >
                          Residuos requeridos más recientes
                        </Heading>
                      </div>
                      <ul className="pl-4">
                        {dataResumen?.resumen?.demanda?.map((demanda, i) => (
                          <li key={i} className="relative my-3 pl-4">
                            <div className="w-1.5 h-1.5 rounded-full bg-uv-secondary-0 absolute left-0 top-2"></div>
                            <Text className="text-sm font-normal">
                              {demanda}
                            </Text>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="shadow-md bg-white p-5 mb-6">
          <Text className="mb-5">
            Todos los indicadores ambientales han sido calculados en base a
            información declarada por los usuarios o empresas, según la
            metodología, fuente de información y supuestos detallados en el
            anexo técnico descargable{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={PDF_FILE}
              className="text-blue-700 underline"
            >
              aquí
            </a>
            . Los usuarios o empresas pueden descargar reportes customizados al
            registrarse y utilizar la plataforma.
          </Text>

          <Text>
            La plataforma también permite calcular indicadores sociales de horas
            de trabajo, paridad y salario generado por la valorización de
            residuos. Para obtener estos indicadores, o explorar otros
            indicadores sociales, puede escribirnos a{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:contacto@plataforma-industria-circular.cl"
              className="text-blue-700 underline"
            >
              contacto@plataforma-industria-circular.cl
            </a>{' '}
            con el asunto “solicitud de indicadores sociales”.
          </Text>
        </div>
      </div>
    </div>
  );
};

const IndicadorItem = ({ title, value, description, icon }) => {
  return (
    <div className="w-full flex-wrap py-3">
      <Text type="label" className="text-uv-primary-0 mb-2 pr-3">
        {title}
      </Text>
      <div className="flex items-center">
        <IconComponent
          color="primary"
          className="bg-uv-primary-90 rounded-full flex w-14 h-14 items-center justify-center p-2.5"
        >
          {icon}
        </IconComponent>

        <div className="pl-3">
          <Heading type="H2" className="font-bold my-0">
            <Cifra valor={value} />
          </Heading>
          <Text className="text-black leading-5 font-normal">
            {description}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default HomeSitio;

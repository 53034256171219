import { PERMISOS } from 'const/Permisos';
import * as yup from 'yup';

const SchemaReceptor = isTienePermisos => {
  const schema = {};

  if (isTienePermisos(PERMISOS.sucursalOrigenEmpresa)) {
    schema.sucursal = yup
      .object()
      .typeError('Este campo es obligatorio')
      .required('Este campo es obligatorio');
  }

  if (isTienePermisos(PERMISOS.nombreResiduo)) {
    schema.nombreResiduo = yup.string().required('Este campo es obligatorio');
  }

  if (isTienePermisos(PERMISOS.tipoResiduo)) {
    schema.tipoResiduo = yup
      .object()
      .typeError('Seleccione tipo de residuo')
      .required('Este campo es obligatorio');
  }

  if (isTienePermisos(PERMISOS.tipoResiduo)) {
    schema.subTipoResiduo = yup
      .object()
      .typeError('Seleccione un subtipo de residuo')
      .required('Este campo es obligatorio');
  }

  if (isTienePermisos(PERMISOS.recursos)) {
    schema.tipoRecurso = yup
      .array()
      .when(['tieneTipoRecursos', 'otroTipoRecurso'], {
        is: (tieneTipoRecursos, otroTipoRecurso) =>
          tieneTipoRecursos && !otroTipoRecurso.length,
        then: () => {
          return yup
            .array()
            .of(yup.object())
            .min(1, 'Debe seleccionar al menos un recurso')
            .required('Este campo es obligatorio');
        }
      });
  }

  if (isTienePermisos(PERMISOS.recursos)) {
    schema.otroTipoRecurso = yup.array().when('tieneTipoRecursos', {
      is: false,
      then: () => {
        return yup
          .array()
          .of(yup.object())
          .min(1, 'Debe seleccionar al menos un recurso distinto')
          .required('Este campo es obligatorio');
      }
    });
  }

  if (isTienePermisos(PERMISOS.recursos)) {
    schema._otraMateriaPrima = yup.string().min(3, 'El nombre es muy corto');
  }

  if (isTienePermisos(PERMISOS.recursoReemplazo)) {
    schema.recursoReemplazo = yup
      .object()
      .typeError('Seleccione un recurso de reemplazo')
      .required('Este campo es obligatorio');
  }

  if (isTienePermisos(PERMISOS.recursoReemplazo)) {
    schema.subTipoRecursoReemplazo = yup
      .object()
      .when('tieneTipoRecursosReemplazo', {
        is: true,
        then: () => {
          return yup
            .object()
            .typeError('Seleccione un subtipo de recurso de reemplazo')
            .required('Este campo es obligatorio');
        }
      });
  }

  if (isTienePermisos(PERMISOS.descripcionResiduo)) {
    schema.descripcionAdicional = yup
      .string()
      .min(3, 'El nombre es muy corto')
      // .max(100,'El nombre no puede ser tan largo'),
      .required('Este campo es obligatorio');
  }

  return yup.object().shape(schema);
};

export { SchemaReceptor };

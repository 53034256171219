import {
  EventAvailable,
  Group,
  Handshake,
  Inventory,
  LibraryAddCheck,
  Place,
  Workspaces
} from '@mui/icons-material';
import { Divider, Tooltip } from '@mui/material';
import ButtonComponent from 'components/design-system/Button/Button';
import Heading from 'components/design-system/Heading/Heading';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import Text from 'components/design-system/Text/Text';
import { DICTONARY } from 'const/Dictonary';
import { getFormatoCantidadUnidad } from 'views/Chat/utils/utils';

export const EncabezadoResiduo = ({
  rsd,
  toggleMenu,
  mostrarBotonTransaccionar,
  transaccionIniciada,
  handleClickOpen,
  roomData
}) => {
  if (!rsd) {
    return;
  }

  return (
    <div className="shadow-sm px-5 py-2 border-b border-b-neutral-90">
      <div className="grid grid-cols-12">
        <div className="col-span-12">
          <div className="grid grid-cols-12 gap-2 items-center">
            <div className="col-span-10">
              <Heading type="h3" className="mb-0">
                {roomData
                  ? '# ' +
                    roomData.codigoSalaChat +
                    ': ' +
                    roomData.nombreSalaChat
                  : 'Sala sin Nombre'}
              </Heading>
            </div>
            <div className="col-span-2 text-end">
              <div className="flex gap-4 justify-end">
                {mostrarBotonTransaccionar && (
                  <Tooltip
                    title={
                      transaccionIniciada
                        ? 'Transacción iniciada'
                        : 'Iniciar transacción'
                    }
                    aria-label={
                      transaccionIniciada
                        ? 'Transacción iniciada'
                        : 'Iniciar transacción'
                    }
                  >
                    <span>
                      <ButtonComponent
                        type="secondary"
                        onClick={handleClickOpen}
                      >
                        <Handshake className="mr-2" />
                        <span className="hidden lg:block">
                          {transaccionIniciada
                            ? 'Transacción iniciada'
                            : 'Iniciar transacción'}
                        </span>
                      </ButtonComponent>
                    </span>
                  </Tooltip>
                )}

                <Tooltip
                  title="Listado de participantes del chat"
                  aria-label="Listado de participantes del chat"
                >
                  <span>
                    <IconButtonComponent type="secondary" onClick={toggleMenu}>
                      <Group />
                    </IconButtonComponent>
                  </span>
                </Tooltip>
              </div>
            </div>

            <div className="col-span-12 pb-2">
              <Divider />
            </div>
          </div>

          <div className="flex flex-wrap justify-start">
            {rsd.cantidad && (
              <div className="w-full xs:w-1/2 lg:w-auto p-2">
                <Text
                  size="S"
                  className="text-neutral-60 text-medium tracking-wide"
                >
                  <Tooltip title="Cantidad" aria-label="Cantidad">
                    <Workspaces className="text-uv-secondary-0" />
                  </Tooltip>
                  <b className="text-black font-medium pl-1">
                    {getFormatoCantidadUnidad(rsd)}
                  </b>
                </Text>
              </div>
            )}

            {rsd.fechaDisponibilidad && (
              <div className="w-full xs:w-1/2 lg:w-auto p-2">
                <Text
                  size="S"
                  className="text-neutral-60 text-medium tracking-wide"
                >
                  <Tooltip
                    title="Fecha disponibilidad"
                    aria-label="Fecha disponibilidad"
                  >
                    <EventAvailable className="text-uv-secondary-0" />
                  </Tooltip>
                  <b className="text-black font-medium pl-1">
                    {rsd.fechaDisponibilidad}
                  </b>
                </Text>
              </div>
            )}

            {rsd.formatoEntrega && (
              <div className="w-full md:w-1/2 lg:w-auto p-2">
                <Text
                  size="S"
                  className="text-neutral-60 text-medium tracking-wide"
                >
                  <Tooltip
                    title="Formato de entrega"
                    aria-label="Formato de entrega"
                  >
                    <Inventory className="text-uv-secondary-0" />
                  </Tooltip>
                  <b className="text-black font-medium pl-1">
                    {rsd.formatoEntrega}
                  </b>
                </Text>
              </div>
            )}

            {rsd && rsd.sucursal && (
              <div className="w-full md:w-1/2 lg:w-auto p-2">
                <Text
                  size="S"
                  className="text-neutral-60 text-medium tracking-wide break-words"
                >
                  <Tooltip title="Dirección" aria-label="Dirección">
                    <Place className="text-uv-secondary-0" />
                  </Tooltip>
                  <b className="text-black font-medium pl-1 break-words">
                    {rsd && rsd.sucursal
                      ? (rsd.sucursal.direccion.nombreVia || '') +
                        ' ' +
                        (rsd.sucursal.direccion.comuna.nombreComuna || '') +
                        ', Región ' +
                        (rsd.sucursal.direccion.comuna.region.nombreRegion ||
                          '')
                      : ''}
                  </b>
                </Text>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

import Heading from 'components/design-system/Heading/Heading';
import { Skeleton } from '@mui/material';

export const BloquesIndicadoresSkeleton = () => {
  return (
    <div>
      <div className="mb-6">
        <Heading type="h3" className="font-bold text-uv-primary-0">
          Transacciones en Marketplace
        </Heading>
        <div className="flex shadow-md flex-col md:flex-row">
          <div className="w-full md:w-1/4 bg-uv-primary-0 rounded-tl rounded-bl">
            <div className="px-6 py-4">
              <div className="mb-8 mt-2">
                <Skeleton
                  variant="rounded"
                  className="w-full h-5 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
                />
                <Skeleton
                  variant="rounded"
                  className="w-full h-14 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
                />
              </div>

              <div className="mb-8 mt-2">
                <Skeleton
                  variant="rounded"
                  className="w-full h-5 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
                />
                <Skeleton
                  variant="rounded"
                  className="w-full h-14 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
                />
              </div>
            </div>
          </div>
          <div className="flex-grow w-auto px-6 py-4 bg-white rounded-tr rounded-br">
            <Skeleton
              variant="rounded"
              className="w-full h-7 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
            />
            <div className="grid grid-cols-1 sm:grid-cols-2 mt-2 gap-5">
              <Skeleton
                variant="rounded"
                className="w-full h-16 rounded relative z-0 min-w-full flex justify-center items-center"
              />
              <Skeleton
                variant="rounded"
                className="w-full h-16 rounded relative z-0 min-w-full flex justify-center items-center"
              />
              <Skeleton
                variant="rounded"
                className="w-full h-16 rounded relative z-0 min-w-full flex justify-center items-center"
              />
              <Skeleton
                variant="rounded"
                className="w-full h-16 rounded relative z-0 min-w-full flex justify-center items-center"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mb-6">
        <Heading type="h3" className="font-bold text-uv-primary-0">
          Declaración en módulo Gestiona
        </Heading>
        <div className="flex shadow-md flex-col md:flex-row">
          <div className="w-full md:w-1/4 bg-uv-primary-0 rounded-tl rounded-bl">
            <div className="px-6 py-4">
              <div className="mb-8 mt-2">
                <Skeleton
                  variant="rounded"
                  className="w-full h-5 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
                />
                <Skeleton
                  variant="rounded"
                  className="w-full h-14 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
                />
              </div>

              <div className="mb-8 mt-2">
                <Skeleton
                  variant="rounded"
                  className="w-full h-5 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
                />
                <Skeleton
                  variant="rounded"
                  className="w-full h-14 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
                />
              </div>
            </div>
          </div>
          <div className="flex-grow w-auto px-6 py-4 bg-white rounded-tr rounded-br">
            <Skeleton
              variant="rounded"
              className="w-full h-7 rounded relative z-0 min-w-full flex justify-center items-center mb-4"
            />
            <div className="grid grid-cols-1 sm:grid-cols-2 mt-2 gap-5">
              <Skeleton
                variant="rounded"
                className="w-full h-16 rounded relative z-0 min-w-full flex justify-center items-center"
              />
              <Skeleton
                variant="rounded"
                className="w-full h-16 rounded relative z-0 min-w-full flex justify-center items-center"
              />
              <Skeleton
                variant="rounded"
                className="w-full h-16 rounded relative z-0 min-w-full flex justify-center items-center"
              />
              <Skeleton
                variant="rounded"
                className="w-full h-16 rounded relative z-0 min-w-full flex justify-center items-center"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const { DICTONARY } = require('const/Dictonary');

const parseDate = function (str) {
  if (typeof str !== 'string') {
    return null;
  }
  let mdy = str.split('/');
  return new Date(mdy[2], mdy[1] - 1, mdy[0]);
};

const datediff = function (first, second) {
  // Take the difference between the dates and divide by milliseconds per day.
  // Round to nearest whole number to deal with DST.
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
};

const onlyNumber = function (text) {
  text = text + '';
  return text.replace(/([^\d])+/g, '');
};

const isDecimal = function (text) {
  text = text + '';
  return text.match(/(^\d*(,[0-9]{0,2})?$)+/);
};

const onlyRutNumber = function (text) {
  text = text + '';
  return text.replace(/[^\d|\-|k|K]/g, '');
};

const getBase64 = file => {
  return new Promise((resolve, rejectede) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      rejectede(error);
    };
  });
};

const formatNumber = function (number, type) {
  if (!number && typeof number != 'number') return 0;

  if (typeof number == 'number') {
    number = number.toString();
  }

  let parteEntera = '';
  let parteDecimal = '';
  let numeroConFormato = '';
  //console.log("number: ", number);
  switch (type) {
    case '0': // number = '12345678.9' => '12.345.678,9'
      parteEntera = number.split('.')[0];
      parteDecimal = number.split('.')[1];
      parteEntera = parteEntera
        .toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.');
      numeroConFormato = parteDecimal
        ? parteEntera + ',' + parteDecimal
        : parteEntera;
      //console.log("numeroConFormato >> ", numeroConFormato);
      return numeroConFormato;

    case '1': // number = '12.345.678,9 => '12345678.9'
      parteEntera = number.split(',')[0];
      parteDecimal = number.split(',')[1];
      parteEntera = parteEntera.toString().replace(/\./g, '');
      numeroConFormato = parteDecimal
        ? parteEntera + '.' + parteDecimal
        : parteEntera;
      //console.log("numeroConFormato >> ", numeroConFormato);
      return parseFloat(numeroConFormato);
    default:
      return number;
  }
};

const isNumberDecimal = num => num % 1 === 0;

const toLocaleStringPLus = function (number) {
  if (!number || !number.toLocaleString) {
    return number;
  }

  if (isNumberDecimal(number)) {
    return number.toLocaleString('ES');
  } else {
    var str = number.toLocaleString('ES', { minimumFractionDigits: 8 });
    return str.replace(/0*$/, '');
  }
};

const getCoordenadasMapa = usuario => {
  try {
    const regionUsuario = usuario.region.codigoRegion;
    if (regionUsuario) {
      return DICTONARY.COORDENAS_CHILE.TODAS[usuario.region.codigoRegion]
        .coordenadas;
    } else {
      return DICTONARY.COORDENAS_CHILE.REGION_METROPOLITANA;
    }
  } catch (error) {
    return DICTONARY.COORDENAS_CHILE.REGION_METROPOLITANA;
  }
};

exports.parseDate = parseDate;
exports.datediff = datediff;
exports.onlyNumber = onlyNumber;
exports.isDecimal = isDecimal;
exports.onlyRutNumber = onlyRutNumber;
exports.getBase64 = getBase64;
exports.formatNumber = formatNumber;
exports.toLocaleStringPLus = toLocaleStringPLus;
exports.getCoordenadasMapa = getCoordenadasMapa;

import React, { useState } from 'react';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import iconReduccionCambioClimatico from 'assets/images/reportes/reduccion-cambio-climatico.svg';
import iconReduccionDemandaAcumulada from 'assets/images/reportes/reduccion-demanda-acumulada.svg';
import iconReduccionFormacionMaterial from 'assets/images/reportes/reduccion-formacion-material.svg';
import iconReduccionRecursoFosil from 'assets/images/reportes/reduccion-recurso-fosil.svg';
import iconHorasDeTrabajo from 'assets/images/reportes/horas-de-trabajo.svg';
import iconSalario from 'assets/images/reportes/salario.svg';
import iconParidadMujeres from 'assets/images/reportes/paridad-mujeres.svg';
import { formatoANumeroIngles } from 'helpers/formateoNumeros';
import { Indicador } from '../Reporte/Indicador';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ButtonComponent from 'components/design-system/Button/Button';

const ResultadoIndicadores = ({ data, onTryAgain }) => {
  const [indicadores] = useState([
    {
      heading: 'Reducción impactos ambientales',
      items: [
        {
          image: iconReduccionCambioClimatico,
          imageAlt: 'Reduccion Recurso Fosil',
          heading: (
            <Text>
              Cambio
              <br />
              climático
            </Text>
          ),
          description: 'kg CO2eq',
          valor: '-',
          element: data.reduccion.cambioClimatico
            ? formatoANumeroIngles(
                data.reduccion.cambioClimatico.reduccion.toString()
              )
            : data.reduccion.cambioClimatico.reduccion
        },
        {
          image: iconReduccionRecursoFosil,
          imageAlt: 'Reduccion Recurso Fosil',
          heading: (
            <Text>
              Agotamiento de
              <br />
              recurso fósil
            </Text>
          ),
          description: 'kg petróleo eq',
          valor: '-',
          element: data.reduccion.recursoFosil
            ? formatoANumeroIngles(
                data.reduccion.recursoFosil.reduccion.toString()
              )
            : data.reduccion.recursoFosil.reduccion
        },
        {
          image: iconReduccionFormacionMaterial,
          imageAlt: 'Reduccion Formacion Material',
          heading: (
            <Text>
              Formación de
              <br />
              material particulado
            </Text>
          ),
          description: 'kgMP 2,5eq',
          valor: '-',
          element: data.reduccion.materialParticulado
            ? formatoANumeroIngles(
                data.reduccion.materialParticulado.reduccion.toString()
              )
            : data.reduccion.materialParticulado.reduccion
        },
        {
          image: iconReduccionDemandaAcumulada,
          imageAlt: 'Reduccion Demanda Acumulada',
          heading: (
            <Text>
              Demanda acumulada
              <br />
              de energía
            </Text>
          ),
          description: 'MJ eq',
          valor: '-',
          element: data.reduccion.demandaAcumulada
            ? formatoANumeroIngles(
                data.reduccion.demandaAcumulada.reduccion.toString()
              )
            : data.reduccion.demandaAcumulada.reduccion
        }
      ]
    },

    {
      heading: 'Indicadores Sociales',
      items: [
        {
          image: iconHorasDeTrabajo,
          imageAlt: 'Horas de trabajo',
          heading: <Text>Horas de trabajo</Text>,
          description: 'H totales',
          valor: '-',
          element: data.social.horasTrabajo
            ? formatoANumeroIngles(data.social.horasTrabajo.toString())
            : data.social.horasTrabajo
        },
        {
          image: iconParidadMujeres,
          imageAlt: 'Paridad Mujeres',
          heading: <Text>Paridad Mujeres</Text>,
          description: 'HM',
          valor: '-',
          element: data.social.horasTrabajoMujer
            ? formatoANumeroIngles(data.social.horasTrabajoMujer.toString())
            : data.social.horasTrabajoMujer
        },
        {
          image: iconSalario,
          imageAlt: 'Salario',
          heading: <Text>Salario</Text>,
          description: 'Mil pesos',
          valor: '-',
          element: data.social.salario
            ? formatoANumeroIngles(data.social.salario.toString())
            : data.social.salario
        }
      ]
    }
  ]);

  return (
    <div>
      <div className="mb-4">
        <Heading type="h2" className="mb-0">
          <IconButtonComponent
            onClick={onTryAgain}
            type="secondary"
            className="mr-2"
          >
            <ChevronLeft />
          </IconButtonComponent>
          Indicadores obtenidos
        </Heading>
        <Text className="mb-0">
          Según los datos ingresados estos indicadores hemos calculado.
        </Text>
      </div>

      {indicadores.map((section, index) => (
        <div className="bg-white shadow-md rounded p-5 mb-5" key={index}>
          <div className="flex flex-wrap justify-center">
            {section.heading && (
              <div className="w-full border-b mb-4">
                <Heading type="h4">{section.heading}</Heading>
              </div>
            )}

            {section.items.map((item, index) => (
              <Indicador
                heading={item.heading}
                image={item.image}
                imageAlt={item.imageAlt}
                element={item.element}
                description={item.description}
                isLoading={false}
                key={index}
              />
            ))}
          </div>
        </div>
      ))}

      <div className="mb-10">
        <ButtonComponent
          type="secondary"
          onClick={onTryAgain}
          className="justify-center"
        >
          <ChevronLeft /> Volver a intentar
        </ButtonComponent>
      </div>
    </div>
  );
};

export default ResultadoIndicadores;

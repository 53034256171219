import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { RUTAS } from 'const/Rutas';
import { format } from 'date-fns';
import residuosServices from 'services/residuos';
import { diccionarioVista } from '../utils/utils';
import { convertirFechaConAjuste } from 'helpers/formatFecha';
import { AuthContext } from 'context';
import { PERMISOS } from 'const/Permisos';
import { isValorValido } from 'helpers/validaCampos';
import { AlertaError, AlertaExito } from 'utils/Alertas';

const ESTADO_INICIAL = {
  // Seccion 1 - Residuo declarado
  nombreLER: null, //opcional
  nombreSubLER: null, //opcional
  nombreSubSubLER: null, //opcional
  tipoResiduo: null, //obligatorio
  subTipoResiduo: null, //obligatorio ?
  sucursal: null, //obligatorio -> usuario no reciclador de base
  fechaTransaccion: '', //obligatorio
  cantidad: null, //obligatorio
  unidadMedida: null, //obligatorio
  levantamientoDatos: null, //opcional

  //Step 2 - Generador Solo reciclador base
  generadorPreCargado: null,
  nombreEmpresaGenerador: null, //obligatorio
  rutEmpresaGenerador: null, //obligatorio
  generadorRazonSocial: null,
  regionEmpresaGenerador: null, //opcional
  comunaEmpresaGenerador: null, //opcional
  nombreViaEmpresaGenerador: null, //opcional
  latitudDireccionEmpresaGenerador: null, //opcional
  longitudDireccionEmpresaGenerador: null, //opcional

  //Step 3 -Transporte
  rutEmpresaTransporte: null, //opcional
  tipoTransporte: null, //obligatorio
  patente: null, //opcional
  nombreEmpresaTransporte: null, //opcional
  transporteRazonSocial: null,

  //Step 4 - Receptor
  receptorPreCargado: null,
  nombreEmpresaReceptor: null, //opcional
  rutEmpresaReceptor: null, //opcional
  receptorRazonSocial: null, //opcional
  regionEmpresaReceptor: null, //opcional
  comunaEmpresaReceptor: null, //opcional
  nombreViaEmpresaReceptor: null, //opcional
  latitudDireccionEmpresaReceptor: null, //opcional
  longitudDireccionEmpresaReceptor: null, //opcional
  tipoTratamiento: null, //opcional
  numeroGuiaDespacho: null, //opcional
  codigoSINADER: null, //opcional

  //Step 5 - Documentos de respaldo
  archivos: []
};

export const useSubmitResiduosDeclarados = (codigoResiduoDeclarado, values) => {
  const { isTienePermisos } = useContext(AuthContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isEliminando, setIsEliminando] = useState(false);
  const [residuoDeclarado, setResiduoDeclarado] = useState(ESTADO_INICIAL);
  const [cargaData, setcargaData] = useState(false);

  /**
   * @name cargarResiduoDeclarado
   * Función para cargar el recurso adquirido que se está desplegando, en el caso de que el código no viene se asume que es un nuevo recurso adquirido.
   * @throws Error
   * @return estructura servicio nueva sucursal
   */

  const cargarResiduoDeclarado = async () => {
    if (codigoResiduoDeclarado !== 'nuevo') {
      setIsLoading(true);
      try {
        const responseResiduoDeclarado =
          await residuosServices.obtieneResiduoDeclarado(
            codigoResiduoDeclarado
          );
        if (responseResiduoDeclarado.status === 200) {
          const residuoDeclarado = responseResiduoDeclarado.data;

          // Nueva forma de formatear la fecha
          const fechaFormateada = convertirFechaConAjuste(
            residuoDeclarado.fechaTransaccion
          );

          //*WARNING*/ Es mandatorio que los campos inicien en null si no hay data.
          setResiduoDeclarado({
            // Seccion 1 - Residuo declarado
            nombreLER: residuoDeclarado.nombreLER || null,
            nombreSubLER: null,
            nombreSubSubLER: residuoDeclarado.nombreLER || null,
            tipoResiduo: null,
            subTipoResiduo: residuoDeclarado.tipoResiduo || null,
            sucursal: residuoDeclarado.sucursal || null,
            fechaTransaccion: fechaFormateada || null,
            cantidad: residuoDeclarado.cantidad || null,
            unidadMedida: residuoDeclarado.unidadMedida || null,
            levantamientoDatos: residuoDeclarado.levantamientoDatos || null,
            formatoEntrega: residuoDeclarado.formatoEntrega || null,

            //Step 2 - Generador Solo reciclador base
            generadorPreCargado: null, //TODO: verificar estructura
            nombreEmpresaGenerador:
              residuoDeclarado.empresaGeneradora?.nombreEmpresa || null,
            rutEmpresaGenerador:
              residuoDeclarado.empresaGeneradora?.rut || null, //obligatorio
            generadorRazonSocial: null,
            regionEmpresaGenerador:
              residuoDeclarado.empresaGeneradora?.direccion?.comuna?.region ||
              null,
            comunaEmpresaGenerador:
              residuoDeclarado.empresaGeneradora?.direccion?.comuna || null,
            nombreViaEmpresaGenerador:
              residuoDeclarado.empresaGeneradora?.direccion?.nombreVia || null,
            latitudDireccionEmpresaGenerador:
              residuoDeclarado.empresaGeneradora?.direccion?.latitudDireccion ||
              null,
            longitudDireccionEmpresaGenerador:
              residuoDeclarado.empresaGeneradora?.direccion
                ?.longitudDireccion || null,

            //Step 3 -Transporte
            rutEmpresaTransporte:
              residuoDeclarado.empresaTransporte?.rut || null,
            tipoTransporte:
              residuoDeclarado.empresaTransporte?.tipoTransporte || null,
            patente: residuoDeclarado.empresaTransporte?.patente || null,
            nombreEmpresaTransporte:
              residuoDeclarado.empresaTransporte?.nombreEmpresa || null,

            //Step 4 - Receptor
            receptorPreCargado: null,
            nombreEmpresaReceptor:
              residuoDeclarado.empresaReceptor?.nombreEmpresa || null,
            rutEmpresaReceptor: residuoDeclarado.empresaReceptor?.rut || null,
            receptorRazonSocial: null, //opcional
            regionEmpresaReceptor:
              residuoDeclarado.empresaReceptor?.direccion?.comuna?.region ||
              null,
            comunaEmpresaReceptor:
              residuoDeclarado.empresaReceptor?.direccion?.comuna || null,
            nombreViaEmpresaReceptor:
              residuoDeclarado.empresaReceptor?.direccion?.nombreVia || null,
            latitudDireccionEmpresaReceptor:
              residuoDeclarado.empresaReceptor?.direccion?.latitudDireccion ||
              null,
            longitudDireccionEmpresaReceptor:
              residuoDeclarado.empresaReceptor?.direccion?.longitudDireccion ||
              null,
            tipoTratamiento:
              residuoDeclarado.empresaReceptor?.tipoTratamiento || null,
            numeroGuiaDespacho:
              residuoDeclarado.empresaReceptor?.numeroGuiaDespacho || null,
            codigoSINADER:
              residuoDeclarado.empresaReceptor?.codigoSINADER || null,

            //Step 5 - Documentos de respaldo
            archivos:
              residuoDeclarado.archivosResiduoDeclarado.length > 0
                ? residuoDeclarado.archivosResiduoDeclarado
                : []
          });
        }
      } catch (error) {
        console.error('Error en cargarResiduoDeclarado', error);
      } finally {
        setIsLoading(false);
        setcargaData(true);
      }
    } else {
      setIsLoading(false);
      setcargaData(true);
    }
  };

  /**
   * @name handleSubmit
   * Función para enviar los datos del formulario al servicio
   * @param valores estructura formik para nuevo residuo declarado
   * @return estructura servicio nuevo residuo declarado
   * @throws Error
   */
  const handleSubmit = async (valores, tipoFormulario) => {
    try {
      if (tipoFormulario === 'ingresar' || tipoFormulario === 'duplicar') {
        await submitNuevoResiduoDeclarado(valores, tipoFormulario);
      } else if (tipoFormulario === 'editar') {
        await submitActualizarResiduoDeclarado(valores);
      }
    } catch (error) {
      console.log('Error en handleSubmit ', error);
      AlertaError(
        `No se ha podido realizar la operación de ${diccionarioVista.nombrePrincipalSingular}`
      );
      throw new Error('Error en handleSubmit ', error);
    }
  };

  /**
   * @name submitNuevoResiduoDeclarado
   * Función para enviar los datos del formulario al servicio
   * @param valores estructura formik
   * @return estructura servicio nuevo residuo declarado
   * @throws Error
   *
   * */
  const submitNuevoResiduoDeclarado = async (valores, tipoFormulario) => {
    const generar = tipoFormulario === 'ingresar' ? 'crear' : 'duplicar';
    const generarado = tipoFormulario === 'ingresar' ? 'creado' : 'duplicado';
    try {
      const residuosDeclaradosData =
        estructuraServicioNuevoResiduoDeclarado(valores);
      const response = await residuosServices.crearResiduoDeclarado(
        residuosDeclaradosData
      );
      if (response.status === 200) {
        AlertaExito(
          `Se ha ${generarado} el ${diccionarioVista.nombrePrincipalSingular} correctamente`
        );
      }
    } catch (error) {
      console.log(
        `Error en ${generar} un ${diccionarioVista.nombrePrincipalSingular} ${error}`
      );
      AlertaError(
        `No se ha podido ${generar} el ${diccionarioVista.nombrePrincipalSingular}`
      );
      throw new Error('Error en submitNuevoResiduoDeclarado ', error);
    }
  };

  /**
   * @name submitActualizarResiduoDeclarado
   * Función para enviar los datos del formulario al servicio
   * @param valores estructura formik para la edición de residuo declarado
   * @return estructura servicio residuo declarado
   * @throws Error
   *
   * */
  const submitActualizarResiduoDeclarado = async valores => {
    try {
      const residuosDeclaradosData =
        estructuraServicioActualizarResiduoDeclarado(valores);
      const codigoResiduoDeclaradoInt = parseInt(codigoResiduoDeclarado);
      const response = await residuosServices.actualizaResiduoDeclarado(
        codigoResiduoDeclaradoInt,
        residuosDeclaradosData
      );
      if (response.status === 200) {
        AlertaExito(
          `Se han editado los datos del ${diccionarioVista.nombrePrincipalSingular} correctamente`
        );
      }
    } catch (error) {
      console.log(
        `Error en la edición de un ${diccionarioVista.nombrePrincipalSingular}`
      );
      throw new Error('Error en submitNuevoRecuroAdquirido ', error);
    }
  };

  /**
   * @name estructuraServicioNuevoRecursoAdquirido
   * Función para estructurar los valores del formulario a la estructura requerida por el servicio
   * @param valores estructura formik
   * @return estructura servicio nuevo/duplicado residuo declarado
   */
  const estructuraServicioNuevoResiduoDeclarado = valores => {
    try {
      const estructuraServicio = {};

      //STEP1
      estructuraServicio.tipoResiduo = {
        codigoTipoResiduo: valores.subTipoResiduo.codigoTipoResiduo
      };
      estructuraServicio.fechaTransaccion = format(
        new Date(valores.fechaTransaccion),
        'yyyy-MM-dd'
      );
      estructuraServicio.cantidad = valores.cantidad;
      estructuraServicio.unidadMedida = {
        codigoUnidadMedida: valores.unidadMedida.codigoUnidadMedida
      };

      if (isValorValido(valores.nombreLER)) {
        estructuraServicio.nombreLER = {
          codigoLER: valores.nombreSubSubLER.codigoLER
        };
      }

      if (
        isTienePermisos(PERMISOS.sucursalOrigenResiduoDeclarado) &&
        isValorValido(valores.sucursal)
      ) {
        estructuraServicio.sucursal = {
          codigoSucursal: valores.sucursal.codigoSucursal
        };
      }

      if (isValorValido(valores.levantamientoDatos)) {
        estructuraServicio.levantamientoDatos = {
          codigoLevantamientoDatos:
            valores.levantamientoDatos.codigoLevantamientoDatos
        };
      }
      if (isValorValido(valores.formatoEntrega)) {
        estructuraServicio.formatoEntrega = valores.formatoEntrega;
      }

      //STEP2 Solo reciclador base.
      if (isTienePermisos(PERMISOS.empresaGeneradoraResiduoDeclarado)) {
        estructuraServicio.empresaGeneradora = {};
        estructuraServicio.empresaGeneradora.nombreEmpresa =
          valores.nombreEmpresaGenerador;
        estructuraServicio.empresaGeneradora.rut = valores.rutEmpresaGenerador;

        if (
          isValorValido(valores.regionEmpresaGenerador) &&
          isValorValido(valores.comunaEmpresaGenerador) &&
          isValorValido(valores.nombreViaEmpresaGenerador)
        ) {
          estructuraServicio.empresaGeneradora.direccion = {
            comuna: {
              codigoComuna: valores.comunaEmpresaGenerador.codigoComuna
            },
            nombreVia: valores.nombreViaEmpresaGenerador,
            latitudDireccion: valores.latitudDireccionEmpresaGenerador,
            longitudDireccion: valores.longitudDireccionEmpresaGenerador
          };
        }
      }

      //STEP3 Transporte
      estructuraServicio.empresaTransporte = {};
      estructuraServicio.empresaTransporte.tipoTransporte = {
        codigoTipoTransporte: valores.tipoTransporte.codigoTipoTransporte
      };

      if (isValorValido(valores.rutEmpresaTransporte)) {
        estructuraServicio.empresaTransporte.rut = valores.rutEmpresaTransporte;
      }
      if (isValorValido(valores.patente)) {
        estructuraServicio.empresaTransporte.patente = valores.patente;
      }
      if (isValorValido(valores.nombreEmpresaTransporte)) {
        estructuraServicio.empresaTransporte.nombreEmpresa =
          valores.nombreEmpresaTransporte;
      }

      //STEP4 Receptor
      estructuraServicio.empresaReceptor = {};
      if (isValorValido(valores.nombreEmpresaReceptor)) {
        estructuraServicio.empresaReceptor.nombreEmpresa =
          valores.nombreEmpresaReceptor;
      }
      if (isValorValido(valores.rutEmpresaReceptor)) {
        estructuraServicio.empresaReceptor.rut = valores.rutEmpresaReceptor;
      }
      if (
        isValorValido(valores.regionEmpresaReceptor) &&
        isValorValido(valores.comunaEmpresaReceptor) &&
        isValorValido(valores.nombreViaEmpresaReceptor)
      ) {
        estructuraServicio.empresaReceptor.direccion = {
          comuna: {
            codigoComuna: valores.comunaEmpresaReceptor.codigoComuna
          },
          nombreVia: valores.nombreViaEmpresaReceptor,
          latitudDireccion: valores.latitudDireccionEmpresaReceptor,
          longitudDireccion: valores.longitudDireccionEmpresaReceptor
        };
      }
      if (isValorValido(valores.tipoTratamiento)) {
        estructuraServicio.empresaReceptor.tipoTratamiento = {
          codigoTipoTratamiento: valores.tipoTratamiento.codigoTipoTratamiento
        };
      }
      if (isValorValido(valores.numeroGuiaDespacho)) {
        estructuraServicio.empresaReceptor.numeroGuiaDespacho =
          valores.numeroGuiaDespacho;
      }
      if (isValorValido(valores.codigoSINADER)) {
        estructuraServicio.empresaReceptor.codigoSINADER =
          valores.codigoSINADER;
      }

      //STEP5 Documentos
      if (isValorValido(valores.archivos) && valores.archivos.length > 0) {
        estructuraServicio.archivosResiduoDeclarado = valores.archivos;
      }
      return estructuraServicio;
    } catch (error) {
      console.log('Error en obtenerEstructuraServicio ', error);
      throw new Error('Error en obtenerEstructuraServicio ', error);
    }
  };

  const estructuraServicioActualizarResiduoDeclarado = valores => {
    try {
      const estructuraServicio = {};

      estructuraServicio.tipoResiduo = {
        codigoTipoResiduo: valores.subTipoResiduo.codigoTipoResiduo
      };
      estructuraServicio.fechaTransaccion = format(
        new Date(valores.fechaTransaccion),
        'yyyy-MM-dd'
      );
      estructuraServicio.cantidad = valores.cantidad;
      estructuraServicio.unidadMedida = {
        codigoUnidadMedida: valores.unidadMedida.codigoUnidadMedida
      };

      if (isValorValido(valores.nombreLER)) {
        estructuraServicio.nombreLER = {
          codigoLER: valores.nombreSubSubLER.codigoLER
        };
      }

      if (
        isTienePermisos(PERMISOS.sucursalOrigenResiduoDeclarado) &&
        isValorValido(valores.sucursal)
      ) {
        estructuraServicio.sucursal = {
          codigoSucursal: valores.sucursal.codigoSucursal
        };
      }

      if (isValorValido(valores.levantamientoDatos)) {
        estructuraServicio.levantamientoDatos = {
          codigoLevantamientoDatos:
            valores.levantamientoDatos.codigoLevantamientoDatos
        };
      }

      if (isValorValido(valores.formatoEntrega)) {
        estructuraServicio.formatoEntrega = valores.formatoEntrega;
      }

      //STEP2 Solo reciclador base.
      if (isTienePermisos(PERMISOS.empresaGeneradoraResiduoDeclarado)) {
        estructuraServicio.empresaGeneradora = {};
        estructuraServicio.empresaGeneradora.nombreEmpresa =
          valores.nombreEmpresaGenerador;
        estructuraServicio.empresaGeneradora.rut = valores.rutEmpresaGenerador;

        if (
          isValorValido(valores.regionEmpresaGenerador) &&
          isValorValido(valores.comunaEmpresaGenerador) &&
          isValorValido(valores.nombreViaEmpresaGenerador)
        ) {
          estructuraServicio.empresaGeneradora.direccion = {
            comuna: {
              codigoComuna: valores.comunaEmpresaGenerador.codigoComuna
            },
            nombreVia: valores.nombreViaEmpresaGenerador,
            latitudDireccion: valores.latitudDireccionEmpresaGenerador,
            longitudDireccion: valores.longitudDireccionEmpresaGenerador
          };
        }
      }

      //STEP3 Transporte
      estructuraServicio.empresaTransporte = {};
      estructuraServicio.empresaTransporte.tipoTransporte = {
        codigoTipoTransporte: valores.tipoTransporte.codigoTipoTransporte
      };

      if (isValorValido(valores.rutEmpresaTransporte)) {
        estructuraServicio.empresaTransporte.rut = valores.rutEmpresaTransporte;
      }
      if (isValorValido(valores.patente)) {
        estructuraServicio.empresaTransporte.patente = valores.patente;
      }
      if (isValorValido(valores.nombreEmpresaTransporte)) {
        estructuraServicio.empresaTransporte.nombreEmpresa =
          valores.nombreEmpresaTransporte;
      }

      //STEP4 Receptor
      estructuraServicio.empresaReceptor = {};
      if (isValorValido(valores.nombreEmpresaReceptor)) {
        estructuraServicio.empresaReceptor.nombreEmpresa =
          valores.nombreEmpresaReceptor;
      }
      if (isValorValido(valores.rutEmpresaReceptor)) {
        estructuraServicio.empresaReceptor.rut = valores.rutEmpresaReceptor;
      }
      if (
        isValorValido(valores.regionEmpresaReceptor) &&
        isValorValido(valores.comunaEmpresaReceptor) &&
        isValorValido(valores.nombreViaEmpresaReceptor)
      ) {
        estructuraServicio.empresaReceptor.direccion = {
          comuna: {
            codigoComuna: valores.comunaEmpresaReceptor.codigoComuna
          },
          nombreVia: valores.nombreViaEmpresaReceptor,
          latitudDireccion: valores.latitudDireccionEmpresaReceptor,
          longitudDireccion: valores.longitudDireccionEmpresaReceptor
        };
      }
      if (isValorValido(valores.tipoTratamiento)) {
        estructuraServicio.empresaReceptor.tipoTratamiento = {
          codigoTipoTratamiento: valores.tipoTratamiento.codigoTipoTratamiento
        };
      }
      if (isValorValido(valores.numeroGuiaDespacho)) {
        estructuraServicio.empresaReceptor.numeroGuiaDespacho =
          valores.numeroGuiaDespacho;
      }
      if (isValorValido(valores.codigoSINADER)) {
        estructuraServicio.empresaReceptor.codigoSINADER =
          valores.codigoSINADER;
      }

      //STEP5 Documentos
      if (isValorValido(valores.archivos) && valores.archivos.length > 0) {
        estructuraServicio.archivosResiduoDeclarado = valores.archivos;
      }

      return estructuraServicio;
    } catch (error) {
      console.log('Error en obtenerEstructuraServicio ', error);
      throw new Error('Error en obtenerEstructuraServicio ', error);
    }
  };

  /**
   * @name handleEliminarResiduoDeclarado
   * Función para eliminar un residuo declarados
   *
   * @param codAdquirido codigo del residuo declarados
   * @param cargaRecursosAdquiridos función para cargar el listado de residuos declarados
   * @return estructura servicio residuo declarados
   * @throws Error
   *
   * */
  const handleEliminarResiduoDeclarado = async (
    codAdquirido,
    cargaResiduosDeclarados
  ) => {
    const params = codAdquirido;
    setIsEliminando(true);
    try {
      const response = await residuosServices.eliminaResiduoDeclarado(params);
      if (response.status === 200) {
        AlertaExito(
          `Se ha eliminado el ${diccionarioVista.nombrePrincipalSingular} correctamente`
        );
        cargaResiduosDeclarados();
        history.push(RUTAS.RECURSOS_ADQUIRIDOS);
      }
    } catch (error) {
      console.log(
        `Error en la eliminación de un ${diccionarioVista.nombrePrincipalSingular} ${error}`
      );
      AlertaError(
        `No se ha podido eliminar el ${diccionarioVista.nombrePrincipalSingular}`
      );
      throw new Error('Error en handleResiduoDeclarado ', error);
    }
    setIsEliminando(false);
  };

  const cargarEstadoVacio = () => {
    setResiduoDeclarado(ESTADO_INICIAL);
  };

  return {
    residuoDeclarado,
    isLoading,
    setIsLoading,
    cargarResiduoDeclarado,
    cargaData,
    handleSubmit,
    isEliminando,
    handleEliminarResiduoDeclarado,
    cargarEstadoVacio
  };
};

import React, { useState, useEffect, useContext } from 'react';
import { FormHelperText } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Divider from '@mui/material/Divider';
import GetAppIcon from '@mui/icons-material/GetApp';
import serviceEmpresa from 'services/empresas';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import URLS from 'URLS';
import { SidebarLayout } from 'layouts/Sidebar/SidebarLayout';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import RadioComponent from 'components/design-system/Radio/Radio';
import LabelComponent from 'components/design-system/Label/Label';
import ButtonComponent from 'components/design-system/Button/Button';
import InputComponent from 'components/design-system/input/Input';
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { es } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { OrdenarAscendente } from 'helpers/manejoArreglos';
import { DICTONARY } from 'const/Dictonary';
import { AuthContext } from 'context';
import { DatePickerComponent } from 'components/design-system/DatePicker/DatePicker';
import { Alerta } from 'utils/Alertas';

var saveFile = (function () {
  var a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  return function (blob, fileName) {
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(a.href);
  };
})();

const DATO_A_EXPORTAR = {
  USUARIO: 'Usuario',
  RESIDUO: 'Residuo',
  INDICADOR: 'Indicador',
  RESIDUO_DECLARADO: 'Residuo declarado',
  RECURSO_ADQUIRIDO: 'Recurso adquirido'
};

const ExportarReportes = props => {
  const {
    perfiles: { isSuperAdmin, isAdmin },
    token,
    logeduser
  } = useContext(AuthContext);
  const initValues = {
    tipo: 'personalizado',
    fechaDisponibilidadInicio: null,
    fechaDisponibilidadTermino: null,
    formato: 'xlsx',
    tipoExportacion: 'Residuo',
    codigoEmpresa: null,
    periodoSinader: 'anual',
    anyo: new Date(),
    mesAnyo: new Date()
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: initValues,
    touched: {},
    errors: {}
  });

  const [downloadUrl, setDownloadUrl] = useState('');
  const [filename, setFilename] = useState('');
  const [creandoArchivo, setCreandoArchivo] = useState(false);

  //sucursales
  const [listaSucursales, setListaSucursales] = useState([]);
  const [listaSucursalesTodas, setListaSucursalesTodas] = useState([]);
  const [sucursalesSelected, setSucursalesSelected] = useState([]);

  //empresa
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [loadEmpresas, setLoadEmpresas] = useState(true);
  const [empresasSelected, setEmpresasSelected] = useState([]);

  const [showDates, setShowDates] = useState(true);
  const [showEmpresa, setShowEmpresa] = useState(true);
  const [showSucursal, setShowSucursal] = useState(true);
  const [showFormato, setShowFormato] = useState(true);
  const [showDatos, setShowDatos] = useState(true);
  const [showPeriodo, setShowPeriodo] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [isShowIndicador, setIsShowIndicador] = useState(false);

  const [isIndicadorSelected, setIsIndicadorSelected] = useState(false);

  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState();
  const [dialogContent, setDialogContent] = useState();
  const [isLoguedEmpresaAsesor, setIsLoguedEmpresaAsesor] = useState();

  // Cambios en values del form
  const handleChange = event => {
    if (event.persist) {
      event.persist();
    }

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      },
      errors: {
        ...formState.errors,
        [event.target.name]: undefined
      }
    }));
  };

  const handleChangeEmpresas = (event, value, reason) => {
    let empresas = value;
    setEmpresasSelected(empresas);
    setSucursalesSelected([]);
  };

  const handleChangeSucursales = (event, value, reason) => {
    // console.log('reason :>> ', reason);
    // console.log('value :>> ', value);
    setSucursalesSelected(value);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleDateChange = name => (jsDate, strDate) => {
    try {
      let fechaFormateada;
      if (name === 'anyo') {
        fechaFormateada = format(jsDate, 'yyyy');
      } else if (name === 'mesAnyo') {
        fechaFormateada = format(jsDate, 'MM-yyyy');
      } else {
        fechaFormateada = format(jsDate, 'yyyy-MM-dd');
      }

      let errors = formState.errors;
      if (Object.hasOwn(errors, name)) {
        delete errors[name];
      }

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          [name]: jsDate,
          [name + '_str']: fechaFormateada
        },
        touched: {
          ...formState.touched,
          [name]: true
        },
        errors: errors
      }));
    } catch (error) {
      setFormState(formState => ({
        ...formState,
        errors: {
          ...formState.errors,
          [name]: 'La fecha ingresada es inválida'
        },
        touched: {
          ...formState.touched,
          [name]: true
        }
      }));
    }
  };

  const handleExportar = async event => {
    setCreandoArchivo(true);
    let errors = {};

    if (showCalendar && formState.values.periodoSinader === 'anual') {
      if (!formState.values.anyo) {
        errors.anyo = ['Año es obligatorio'];
      }
    }
    if (showCalendar && formState.values.periodoSinader === 'mensual') {
      if (!formState.values.mesAnyo) {
        errors.mesAnyo = ['Mes es obligatorio'];
      }
    }

    if (
      formState.values.tipo !== 'sinader' &&
      (formState.values.tipoExportacion === DATO_A_EXPORTAR.RESIDUO ||
        formState.values.tipoExportacion ===
          DATO_A_EXPORTAR.RECURSO_ADQUIRIDO ||
        formState.values.tipoExportacion === DATO_A_EXPORTAR.RESIDUO_DECLARADO)
    ) {
      if (!formState.values.fechaDisponibilidadInicio) {
        errors.fechaDisponibilidadInicio = ['Fecha de inicio es obligatoria'];
      }

      if (
        !formState.values.fechaDisponibilidadTermino &&
        formState.values.tipo !== 'sinader'
      ) {
        errors.fechaDisponibilidadTermino = ['Fecha de término es obligatoria'];
      }
    }

    if (
      formState.values.fechaDisponibilidadInicio >
        formState.values.fechaDisponibilidadTermino &&
      (formState.values.tipoExportacion === DATO_A_EXPORTAR.RESIDUO ||
        formState.values.tipoExportacion ===
          DATO_A_EXPORTAR.RECURSO_ADQUIRIDO ||
        formState.values.tipoExportacion === DATO_A_EXPORTAR.RESIDUO_DECLARADO)
    ) {
      errors.fechaDisponibilidadTermino = [
        'Fecha de término debe ser posterior a la fecha de inicio'
      ];
      errors.fechaDisponibilidadInicio = [
        'Fecha de inicio debe ser anterior a la fecha de término'
      ];
    }

    if (formState.values.tipo === 'personalizado') {
      if (
        formState.values.tipoExportacion === DATO_A_EXPORTAR.RESIDUO ||
        formState.values.tipoExportacion === DATO_A_EXPORTAR.USUARIO ||
        formState.values.tipoExportacion ===
          DATO_A_EXPORTAR.RECURSO_ADQUIRIDO ||
        formState.values.tipoExportacion === DATO_A_EXPORTAR.RESIDUO_DECLARADO
      ) {
        if (sucursalesSelected.length === 0) {
          errors.sucursales = ['Seleccione al menos una sucursal'];
        }
      }
    }

    let touched = {};
    if (Object.keys(errors).length > 0) {
      for (const k in errors) {
        if (errors.hasOwnProperty(k)) {
          touched[k] = true;
        }
      }
    }
    const isValid = Object.keys(errors).length > 0 ? false : true;
    setFormState(formState => ({
      ...formState,
      isValid: isValid,
      errors: errors || {},
      touched: {
        ...formState.touched,
        ...touched
      }
    }));

    if (Object.keys(errors).length > 0) {
      console.log('errors :>> ', errors);
    }

    if (!isValid) {
      setCreandoArchivo(false);
      return;
    }

    let url = '';
    const options = {
      headers: {
        Authorization: 'Bearer ' + token
      }
    };

    if (formState.values.tipo === 'sinader') {
      url = `${URLS.baseURL}/empresas/exportar-archivo/sinader?${downloadUrl}`;
    } else {
      if (formState.values.tipoExportacion === DATO_A_EXPORTAR.RESIDUO) {
        url = `${URLS.baseURL}/residuos/exportar-archivo?${downloadUrl}`;
      } else if (formState.values.tipoExportacion === DATO_A_EXPORTAR.USUARIO) {
        url = `${URLS.baseURL}/usuarios/exportar-archivo?${downloadUrl}`;
      } else if (
        formState.values.tipoExportacion === DATO_A_EXPORTAR.INDICADOR
      ) {
        url = `${URLS.baseURL}/indicadores/exportar-archivo?${downloadUrl}`;
      } else if (
        formState.values.tipoExportacion === DATO_A_EXPORTAR.RESIDUO_DECLARADO
      ) {
        url = `${URLS.baseURL}/residuos-declarados/exportar-archivo?${downloadUrl}`;
      } else if (
        formState.values.tipoExportacion === DATO_A_EXPORTAR.RECURSO_ADQUIRIDO
      ) {
        url = `${URLS.baseURL}/recursos-adquiridos/exportar-archivo?${downloadUrl}`;
      } else {
        return;
      }
    }

    fetch(url, options)
      .then(async response => {
        try {
          const data = await response.clone().json();
          if (data.error) {
            const codigo = data.error.codigo;
            Alerta(codigo);
            throw new Error('ERROR_SIN_DATOS');
          }

          return response.blob();
        } catch (error) {
          const errorObj = { error };
          if (errorObj.error?.message === 'ERROR_SIN_DATOS') {
            throw new Error('ERROR_SIN_DATOS');
          }

          return response.blob();
        }
      })
      .then(blob => {
        if (blob) {
          saveFile(blob, filename);
          setCreandoArchivo(false);
        }
      })
      .catch(error => {
        setCreandoArchivo(false);
      })
      .finally(() => {
        setCreandoArchivo(false);
      });
  };

  const cargarSucursales = async () => {
    try {
      let sucursales = [];
      for (let index = 0; index < empresasSelected.length; index++) {
        const empresa = empresasSelected[index];
        const request = await serviceEmpresa.obtieneSucursales({
          codigoEmpresa: empresa.codigoEmpresa,
          totalPagina: DICTONARY.PAGINACION.ALL_DATA
        });
        sucursales.push(...request.data.content);
      }

      const sucursalesOrdenadas = OrdenarAscendente(
        sucursales,
        'nombreSucursal'
      );
      setListaSucursales(sucursalesOrdenadas);
    } catch (error) {
      console.log('error al cargar sucursales', error);
    }
  };

  useEffect(() => {
    //  console.log('isIndicadorSelected :>> ', isIndicadorSelected);
  }, [isIndicadorSelected]);

  useEffect(() => {
    cargarSucursales();
  }, [empresasSelected]);

  const handleSignUp = event => {};

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  // OBTENER EMPRESA / SUCURSALES
  useEffect(() => {
    if (!logeduser) {
      return;
    }

    if (isAdmin) {
      // Si el usuario es un Admin entonces solo tiene acceso a su empresa y sus sucursales
      try {
        setIsLoguedEmpresaAsesor(
          logeduser.perfiles[0].nombrePerfil == 'asesor'
        );

        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            codigoEmpresa: logeduser.sucursal.empresa.codigoEmpresa
          },
          touched: {
            ...formState.touched,
            codigoEmpresa: true
          },
          errors: {
            ...formState.touched,
            codigoEmpresa: undefined
          }
        }));

        setEmpresasSelected([logeduser.sucursal.empresa]);

        // Si el usuario es Admin de una empresa asesora entonces funciona igual que el Super Admin
        if (
          logeduser.perfiles.find(perfil =>
            perfil.nombrePerfil.toLowerCase().includes('asesor')
          )
        ) {
          // obtener todas las empresas
          serviceEmpresa
            .obtieneEmpresasSuperAdmin({
              totalPagina: DICTONARY.PAGINACION.ALL_DATA
            })
            .then(result => {
              if (Array.isArray(result.data.content)) {
                const empresasOrdenadas = OrdenarAscendente(
                  result.data.content,
                  'nombreEmpresa'
                );
                setListaEmpresas(empresasOrdenadas);
              }
              setLoadEmpresas(false);
            })
            .catch(err => {
              console.error(err);
            });

          // obtener todas las sucursales
          serviceEmpresa
            .obtieneSucursales({ totalPagina: DICTONARY.PAGINACION.ALL_DATA })
            .then(result => {
              if (Array.isArray(result.data.content)) {
                setListaSucursalesTodas(result.data.content);
              }
              //setLoadSucursales(false);
            })
            .catch(err => {
              console.error(err);
            });
        } else {
          let querySucursales = {
            codigoEmpresa: logeduser.sucursal.empresa.codigoEmpresa,
            totalPagina: DICTONARY.PAGINACION.ALL_DATA
          };

          // obtener las sucursales de su empresa
          serviceEmpresa
            .obtieneSucursales(querySucursales)
            .then(result => {
              if (Array.isArray(result.data.content)) {
                setListaSucursalesTodas(result.data.content);
              }
              //setLoadSucursales(false);
            })
            .catch(err => {
              console.error(err);
            });

          setListaEmpresas(logeduser.sucursal.empresa);
          setLoadEmpresas(false);
        }
      } catch (err) {
        console.error(err);
      }
    } else if (isSuperAdmin) {
      // Si el usuario es Super Admin se obtienen todas las empresas y todas las sucursales
      // las sucursales a elegir se filtran en base a las empresas elejidas

      // obtener todas las empresas
      serviceEmpresa
        .obtieneEmpresasSuperAdmin({
          totalPagina: DICTONARY.PAGINACION.ALL_DATA
        })
        .then(result => {
          if (Array.isArray(result.data.content)) {
            const empresasOrdenadas = OrdenarAscendente(
              result.data.content,
              'nombreEmpresa'
            );
            setListaEmpresas(empresasOrdenadas);
          }
          setLoadEmpresas(false);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [logeduser]);

  // Tipo de exportacion default
  useEffect(() => {
    let tipo = 'personalizado';
    if (isSuperAdmin) {
      tipo = 'completo';
    }
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        tipo: tipo
      }
    }));
  }, [isSuperAdmin, isAdmin]);

  useEffect(() => {
    // Reiniciar los valores del formulario
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        fechaDisponibilidadInicio: null,
        fechaDisponibilidadTermino: null,
        anyo: null,
        mesAnyo: null,
        fechaDisponibilidadInicio_str: '',
        fechaDisponibilidadTermino_str: '',
        anyo_str: '',
        mesAnyo_str: ''
      }
    }));

    // Configurar las empresas seleccionadas
    if (logeduser && !isSuperAdmin) {
      setEmpresasSelected([logeduser.sucursal.empresa]);
    } else {
      setEmpresasSelected([]);
    }

    // Lógica específica para "sinader"
    if (formState.values.tipo === 'sinader') {
      setShowDates(false);
      setShowSucursal(false);
      setShowEmpresa(false);
      setShowDatos(false);
      setShowFormato(false);
      setShowPeriodo(true);
      setShowCalendar(true);
      setIsShowIndicador(false);
      return; // Salir temprano si es "sinader"
    }

    // Configuración de visibilidad y estado basada en el tipo de exportación y tipo
    const configByExportType = {
      [DATO_A_EXPORTAR.RESIDUO]: {
        personalizado: {
          showDates: true,
          showEmpresa: true,
          showSucursal: true,
          showDatos: true,
          showFormato: true,
          showPeriodo: false,
          showCalendar: false,
          isShowIndicador: true
        },
        completo: {
          showDates: true,
          showEmpresa: false,
          showSucursal: false,
          showDatos: true,
          showFormato: true,
          showPeriodo: false,
          showCalendar: false,
          isShowIndicador: false
        }
      },

      [DATO_A_EXPORTAR.USUARIO]: {
        personalizado: {
          showDates: false,
          showEmpresa: true,
          showSucursal: true,
          showDatos: true,
          showFormato: true,
          showPeriodo: false,
          showCalendar: false,
          isShowIndicador: true
        },
        completo: {
          showDates: false,
          showEmpresa: false,
          showSucursal: false,
          showDatos: true,
          showFormato: true,
          showPeriodo: false,
          showCalendar: false,
          isShowIndicador: false
        }
      },

      [DATO_A_EXPORTAR.INDICADOR]: {
        personalizado: {
          showDates: false,
          showEmpresa: true,
          showSucursal: false,
          showDatos: true,
          showFormato: true,
          showPeriodo: false,
          showCalendar: false,
          isShowIndicador: true
        },
        completo: {
          showDates: false,
          showEmpresa: false,
          showSucursal: false,
          showDatos: true,
          showFormato: true,
          showPeriodo: false,
          showCalendar: false,
          isShowIndicador: false
        }
      },

      [DATO_A_EXPORTAR.RESIDUO_DECLARADO]: {
        personalizado: {
          showDates: true,
          showEmpresa: true,
          showSucursal: true,
          showDatos: true,
          showFormato: true,
          showPeriodo: false,
          showCalendar: false,
          isShowIndicador: true
        },
        completo: {
          showDates: true,
          showEmpresa: false,
          showSucursal: false,
          showDatos: true,
          showFormato: true,
          showPeriodo: false,
          showCalendar: false,
          isShowIndicador: false
        }
      },

      [DATO_A_EXPORTAR.RECURSO_ADQUIRIDO]: {
        personalizado: {
          showDates: true,
          showEmpresa: true,
          showSucursal: true,
          showDatos: true,
          showFormato: true,
          showPeriodo: false,
          showCalendar: false,
          isShowIndicador: true
        },
        completo: {
          showDates: true,
          showEmpresa: false,
          showSucursal: false,
          showDatos: true,
          showFormato: true,
          showPeriodo: false,
          showCalendar: false,
          isShowIndicador: false
        }
      }
    };

    const currentConfig =
      configByExportType[formState.values.tipoExportacion]?.[
        formState.values.tipo
      ];

    if (currentConfig) {
      setShowDates(currentConfig.showDates);
      setShowSucursal(currentConfig.showSucursal);
      setShowEmpresa(currentConfig.showEmpresa);
      setShowDatos(currentConfig.showDatos);
      setShowFormato(currentConfig.showFormato);
      setShowPeriodo(currentConfig.showPeriodo);
      setShowCalendar(currentConfig.showCalendar);
      setIsShowIndicador(currentConfig.isShowIndicador);
    }

    // Ajustar el formato a PDF si el tipo de exportación es INDICADOR
    if (formState.values.tipoExportacion === DATO_A_EXPORTAR.INDICADOR) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          formato: 'pdf'
        },
        touched: {
          ...formState.touched,
          formato: true
        },
        errors: {
          ...formState.errors,
          formato: undefined
        }
      }));
    }
  }, [formState.values.tipoExportacion, formState.values.tipo, isSuperAdmin]);

  // Obtener url y filename a partir de opciones seleccionadas
  useEffect(() => {
    //EXPORTAR_USUARIOS_20230421113815.pdf
    let filename = 'EXPORTAR_';
    let newUrl = '';
    let urlFormato = '';
    let urlTipoDato = '';

    filename += formState.values.tipoExportacion.toUpperCase();
    urlFormato += 'formato=' + formState.values.formato;
    // console.log(formState.values);

    if (formState.values.tipo === 'personalizado') {
      // en reportes personalizados se pueden elegir empresas y sucursales

      switch (formState.values.tipoExportacion) {
        // en Residuo personalizado se envian fechas y sucursales
        case DATO_A_EXPORTAR.RESIDUO:
          if (
            empresasSelected.length > 0 &&
            formState.values.fechaDisponibilidadInicio_str &&
            formState.values.fechaDisponibilidadTermino_str
          ) {
            let urlSucursales = '';
            let urlFechaInicio =
              '&fechaInicio=' + formState.values.fechaDisponibilidadInicio_str;
            let urlFechaTermino =
              '&fechaTermino=' +
              formState.values.fechaDisponibilidadTermino_str;
            //sucursales
            sucursalesSelected.map(sucursal => {
              urlSucursales += '&codigoSucursal=' + sucursal.codigoSucursal;
            });
            newUrl +=
              urlFormato +
              urlTipoDato +
              urlSucursales +
              urlFechaInicio +
              urlFechaTermino;
          }
          break;

        // en usuarios personalizado se envian sucursales
        case DATO_A_EXPORTAR.USUARIO:
          if (
            empresasSelected.length > 0
            // && sucursalesSelected.length > 0
          ) {
            // let urlSucursales = '&codSucursal=';
            let urlSucursales = '';
            //sucursales
            sucursalesSelected.map(sucursal => {
              urlSucursales += '&codigoSucursal=' + sucursal.codigoSucursal;
            });
            newUrl += urlFormato + urlTipoDato + urlSucursales;
          }
          break;

        // en indicadores personalizado se envian empresas
        case DATO_A_EXPORTAR.INDICADOR:
          if (isSuperAdmin) {
            if (empresasSelected) {
              let urlEmpresas =
                '&codigoEmpresa=' + empresasSelected.codigoEmpresa;
              newUrl += urlFormato + urlTipoDato + urlEmpresas;
            }
          } else {
            let urlEmpresas =
              '&codigoEmpresa=' + logeduser.sucursal.empresa.codigoEmpresa;
            newUrl += urlFormato + urlTipoDato + urlEmpresas;
          }
          break;

        case DATO_A_EXPORTAR.RESIDUO_DECLARADO:
          if (empresasSelected.length > 0) {
            let urlSucursales = '';
            let urlFechaInicio =
              '&fechaInicio=' + formState.values.fechaDisponibilidadInicio_str;
            let urlFechaTermino =
              '&fechaTermino=' +
              formState.values.fechaDisponibilidadTermino_str;

            sucursalesSelected.map(sucursal => {
              urlSucursales += '&codigoSucursal=' + sucursal.codigoSucursal;
            });

            newUrl +=
              urlFormato +
              urlTipoDato +
              urlSucursales +
              urlFechaInicio +
              urlFechaTermino;
          }
          break;

        case DATO_A_EXPORTAR.RECURSO_ADQUIRIDO:
          if (empresasSelected.length > 0) {
            let urlSucursales = '';
            let urlFechaInicio =
              '&fechaInicio=' + formState.values.fechaDisponibilidadInicio_str;
            let urlFechaTermino =
              '&fechaTermino=' +
              formState.values.fechaDisponibilidadTermino_str;

            sucursalesSelected.map(sucursal => {
              urlSucursales += '&codigoSucursal=' + sucursal.codigoSucursal;
            });

            newUrl +=
              urlFormato +
              urlTipoDato +
              urlSucursales +
              urlFechaInicio +
              urlFechaTermino;
          }
          break;

        default:
          break;
      }
    } else if (formState.values.tipo === 'completo') {
      // en reportes completos se exportan todas las empresas y sucursales
      // (opcion disponible solo para Super Admins y Admins de empresa tipo 'asesor')

      if (formState.values.tipoExportacion === DATO_A_EXPORTAR.INDICADOR) {
        formState.values.tipoExportacion = '';
        return;
      }

      switch (formState.values.tipoExportacion) {
        // en Residuo completo solo se eligen fechas
        case DATO_A_EXPORTAR.RESIDUO:
          if (
            formState.values.fechaDisponibilidadInicio_str &&
            formState.values.fechaDisponibilidadTermino_str
          ) {
            let urlFechaInicio =
              '&fechaInicio=' + formState.values.fechaDisponibilidadInicio_str;
            let urlFechaTermino =
              '&fechaTermino=' +
              formState.values.fechaDisponibilidadTermino_str;

            newUrl +=
              urlFormato + urlTipoDato + urlFechaInicio + urlFechaTermino;
          }
          break;

        // en usuarios completo no se elige nada
        case DATO_A_EXPORTAR.USUARIO:
          newUrl += urlFormato + urlTipoDato;
          break;

        // en indicador completo no se elige nada
        case DATO_A_EXPORTAR.INDICADOR:
          newUrl += urlFormato + urlTipoDato;
          break;

        case DATO_A_EXPORTAR.RESIDUO_DECLARADO:
          if (
            formState.values.fechaDisponibilidadInicio_str &&
            formState.values.fechaDisponibilidadTermino_str
          ) {
            let urlFechaInicio =
              '&fechaInicio=' + formState.values.fechaDisponibilidadInicio_str;
            let urlFechaTermino =
              '&fechaTermino=' +
              formState.values.fechaDisponibilidadTermino_str;
            newUrl +=
              urlFormato + urlTipoDato + urlFechaInicio + urlFechaTermino;
          }
          break;

        case DATO_A_EXPORTAR.RECURSO_ADQUIRIDO:
          if (
            formState.values.fechaDisponibilidadInicio_str &&
            formState.values.fechaDisponibilidadTermino_str
          ) {
            let urlFechaInicio =
              '&fechaInicio=' + formState.values.fechaDisponibilidadInicio_str;
            let urlFechaTermino =
              '&fechaTermino=' +
              formState.values.fechaDisponibilidadTermino_str;
            newUrl +=
              urlFormato + urlTipoDato + urlFechaInicio + urlFechaTermino;
          }
          break;

        default:
          break;
      }
    } else if (formState.values.tipo === 'sinader') {
      urlFormato = 'formato=xlsx';
      if (
        formState.values.periodoSinader === 'anual' &&
        formState.values.anyo_str
      ) {
        let urlAnyo = '&anyo=' + formState.values.anyo_str;
        newUrl += urlFormato + urlTipoDato + urlAnyo;
      } else if (
        formState.values.periodoSinader === 'mensual' &&
        formState.values.mesAnyo_str
      ) {
        let anyo = formState.values.mesAnyo_str.split('-');

        let urlMes = '&mes=' + anyo[0];
        let urlAnyo = '&anyo=' + anyo[1];
        newUrl += urlFormato + urlTipoDato + urlMes + urlAnyo;
      }

      if (isAdmin) {
        try {
          newUrl +=
            '&codigoEmpresa=' + logeduser.sucursal.empresa.codigoEmpresa;
        } catch (error) {}
      }
    }

    const fecha = new Date();
    const fechaFormateada = format(fecha, 'yyyyMMddHHmmss');

    filename += `_${fechaFormateada}.${formState.values.formato}`;

    setFilename(filename);
    setDownloadUrl(newUrl);
  }, [formState.values, sucursalesSelected, empresasSelected]);

  return (
    <SidebarLayout>
      <div className="flex items-center mb-4">
        <div>
          <Heading type="h2" className="mb-0">
            Reportes
          </Heading>
          <Text className="mb-4">
            Genera reportes personalizados según los criterios que tu definas
            basados en las estadísticas de la plataforma.
          </Text>
        </div>
      </div>

      <form className="" onSubmit={handleSignUp}>
        <div className="bg-white rounded shadow-md mb-5">
          <div className="grid grid-cols-12 p-5 gap-5">
            <div className="col-span-12">
              <RadioGroup
                aria-label="tipo"
                name="tipo"
                value={formState.values.tipo}
                onChange={handleChange}
              >
                <div className="grid grid-cols-12 gap-5">
                  <div className="col-span-12">
                    <Text>Selecciona el tipo de reporte a generar:</Text>
                  </div>

                  <div className="col-span-12 md:col-span-6 pr-10">
                    <FormControlLabel
                      className="px-2"
                      value="personalizado"
                      control={<RadioComponent />}
                      label={
                        <Heading type="h4" className="text-uv-primary-0 mb-1">
                          Personalizado
                        </Heading>
                      }
                    />
                    <Text className="pl-10">
                      Podrás seleccionar manualmente las empresas de las cuales
                      quieres obtener el reporte.
                    </Text>
                  </div>

                  {(isLoguedEmpresaAsesor && isAdmin) || isSuperAdmin ? (
                    <div className="col-span-12 md:col-span-6 pr-10">
                      <div>
                        <FormControlLabel
                          className="px-2"
                          value="completo"
                          control={<RadioComponent />}
                          label={
                            <Heading
                              type="h4"
                              className="text-uv-primary-0 mb-1"
                            >
                              Completo
                            </Heading>
                          }
                        />
                        <Text className="pl-10">
                          Se exportarán todas las empresas registradas en
                          sistema junto con todas sus sucursales.
                        </Text>
                      </div>
                    </div>
                  ) : null}

                  <div className="col-span-12 md:col-span-6 pr-10">
                    <FormControlLabel
                      className="px-2"
                      value="sinader"
                      control={<RadioComponent />}
                      label={
                        <Heading type="h4" className="text-uv-primary-0 mb-1">
                          SINADER
                        </Heading>
                      }
                    />
                    <Text className="pl-10">
                      Se exportarán las transacciones finalizadas de
                      residuo-recurso del <strong>Marketplace</strong> y
                      residuos declarados en <strong>Gestiona</strong>, en
                      formato apto para la opción "carga masiva de residuos", de
                      las declaraciones mensuales en la plataforma SINADER.
                    </Text>
                  </div>
                </div>
              </RadioGroup>
            </div>

            <div className="col-span-12 my-4">
              <Divider />
            </div>

            {showDatos && (
              <div className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-3">
                <AutocompleteComponent
                  id="tipoExportacion"
                  autoComplete={false}
                  disableClearable={false}
                  onKeyPress={e => {
                    e.preventDefault();
                  }}
                  clearIcon={null}
                  name="tipoExportacion"
                  options={[
                    DATO_A_EXPORTAR.USUARIO,
                    DATO_A_EXPORTAR.RESIDUO,
                    // mostrar si el state isShowIndicador es true
                    ...(isShowIndicador ? [DATO_A_EXPORTAR.INDICADOR] : []),
                    DATO_A_EXPORTAR.RESIDUO_DECLARADO,
                    DATO_A_EXPORTAR.RECURSO_ADQUIRIDO
                  ]}
                  onChange={ev => {
                    // console.log('ev.target.innerText :>> ', ev.target.innerText);
                    const target = {
                      name: 'tipoExportacion',
                      value: ev.target.innerText
                    };
                    if (target.value === 'Indicador') {
                      setIsIndicadorSelected(true);
                    } else {
                      setIsIndicadorSelected(false);
                    }
                    handleChange({ target });
                  }}
                  value={formState.values.tipoExportacion || ''}
                  isOptionEqualToValue={(option, value) => {
                    return option === value;
                  }}
                >
                  <InputComponent
                    autoComplete={'off'}
                    readOnly={true}
                    fullWidth
                    label={<LabelComponent>Datos a exportar</LabelComponent>}
                  />
                </AutocompleteComponent>
              </div>
            )}

            {showFormato && (
              <div className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-3">
                <LabelComponent>Formato de Archivo</LabelComponent>
                <RadioGroup
                  row
                  name="formato"
                  defaultValue="xlsx"
                  value={formState.values.formato || ''}
                  onChange={handleChange}
                >
                  {formState.values.tipoExportacion === 'Indicador' ? (
                    <FormControlLabel
                      value="pdf"
                      control={<RadioComponent />}
                      label={<LabelComponent>PDF</LabelComponent>}
                      disabled={
                        formState.values.tipoExportacion === 'Indicador'
                      }
                    />
                  ) : (
                    <React.Fragment>
                      <FormControlLabel
                        value="xlsx"
                        control={<RadioComponent />}
                        label={<LabelComponent>XLSX</LabelComponent>}
                      />
                      <FormControlLabel
                        value="csv"
                        control={<RadioComponent />}
                        label={<LabelComponent>CSV</LabelComponent>}
                      />
                    </React.Fragment>
                  )}
                </RadioGroup>
              </div>
            )}

            {showPeriodo && (
              <div className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-3">
                <LabelComponent>Período</LabelComponent>
                <RadioGroup
                  row
                  aria-label="position"
                  name="periodoSinader"
                  value={formState.values.periodoSinader}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="anual"
                    className="mr-5"
                    control={<RadioComponent />}
                    label={<LabelComponent>Anual</LabelComponent>}
                  />
                  <FormControlLabel
                    value="mensual"
                    control={<RadioComponent />}
                    label={<LabelComponent>Mensual</LabelComponent>}
                  />
                </RadioGroup>
              </div>
            )}

            {showCalendar && formState.values.periodoSinader === 'anual' ? (
              <div className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-3">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={es}
                >
                  <DesktopDatePicker
                    onChange={handleDateChange('anyo')}
                    views={['year']}
                    name="anyo"
                    label={<LabelComponent>Seleccionar año</LabelComponent>}
                    defaultValue={formState.values.anyo}
                    value={formState.values.anyo}
                    slots={{ textField: DatePickerComponent }}
                  />
                  {hasError('anyo') ? (
                    <FormHelperText>{formState.errors.anyo}</FormHelperText>
                  ) : null}
                </LocalizationProvider>
              </div>
            ) : null}

            {showCalendar && formState.values.periodoSinader === 'mensual' ? (
              <div className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-3">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={es}
                >
                  <DesktopDatePicker
                    onChange={handleDateChange('mesAnyo')}
                    views={['year', 'month']}
                    format="MM/yyyy"
                    name="mesAnyo"
                    label={<LabelComponent>Seleccionar mes</LabelComponent>}
                    defaultValue={formState.values.mesAnyo}
                    value={formState.values.mesAnyo}
                    slots={{ textField: DatePickerComponent }}
                  />
                  {hasError('mesAnyo') ? (
                    <FormHelperText>{formState.errors.mesAnyo}</FormHelperText>
                  ) : null}
                </LocalizationProvider>
              </div>
            ) : null}

            {showDates ? (
              <>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={es}
                >
                  <div className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-3">
                    <DesktopDatePicker
                      onChange={handleDateChange('fechaDisponibilidadInicio')}
                      label={<LabelComponent>Fecha inicio</LabelComponent>}
                      inputFormat="dd/MM/yyyy"
                      defaultValue={formState.values.fechaDisponibilidadInicio}
                      value={formState.values.fechaDisponibilidadInicio}
                      maxDate={formState.values.fechaDisponibilidadTermino}
                      slots={{ textField: DatePickerComponent }}
                    />
                  </div>

                  <div className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-3">
                    <DesktopDatePicker
                      onChange={handleDateChange('fechaDisponibilidadTermino')}
                      label={<LabelComponent>Fecha término</LabelComponent>}
                      inputFormat="dd/MM/yyyy"
                      minDate={formState.values.fechaDisponibilidadInicio}
                      defaultValue={formState.values.fechaDisponibilidadTermino}
                      value={formState.values.fechaDisponibilidadTermino}
                      slots={{ textField: DatePickerComponent }}
                    />
                  </div>
                </LocalizationProvider>
              </>
            ) : null}

            {showEmpresa ? (
              isSuperAdmin || isLoguedEmpresaAsesor ? (
                <div className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-3">
                  <AutocompleteComponent
                    multiple={!isIndicadorSelected ? true : false}
                    options={listaEmpresas}
                    accesor="nombreEmpresa"
                    disabled={!(isSuperAdmin || isLoguedEmpresaAsesor)}
                    onChange={handleChangeEmpresas}
                    value={empresasSelected}
                    getOptionLabel={option => option.nombreEmpresa || ''}
                  >
                    <InputComponent
                      fullWidth
                      label={
                        <LabelComponent>
                          {isIndicadorSelected
                            ? 'Selección de empresa'
                            : 'Seleccionar la(s) empresa(s)'}
                        </LabelComponent>
                      }
                    />
                  </AutocompleteComponent>
                </div>
              ) : null
            ) : null}

            {showSucursal ? (
              <div className="col-span-12 sm:col-span-6 md:col-span-4 xl:col-span-3">
                <AutocompleteComponent
                  multiple
                  options={listaSucursales}
                  accesor="nombreSucursal"
                  disabled={empresasSelected.length === 0}
                  onChange={handleChangeSucursales}
                  value={sucursalesSelected}
                  getOptionLabel={option => option.nombreSucursal || ''}
                >
                  <InputComponent
                    fullWidth
                    label={
                      <LabelComponent>
                        Seleccionar la(s) sucursal(es)
                      </LabelComponent>
                    }
                    touched={formState.touched.sucursales ? true : false}
                    estado={
                      formState.errors.sucursales
                        ? {
                            tipo: 'error',
                            mensaje: formState.errors.sucursales
                          }
                        : null
                    }
                  />
                </AutocompleteComponent>
              </div>
            ) : null}

            <div className="col-span-12">
              <Divider />
            </div>

            <div className="col-span-12">
              <ButtonComponent
                onClick={handleExportar}
                disabled={creandoArchivo}
                type="primary"
              >
                Obtener reporte
                <GetAppIcon className="ml-1" />
              </ButtonComponent>
            </div>
          </div>
        </div>
      </form>

      <Dialog
        open={open}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <strong>{dialogTitle}</strong>
        </DialogTitle>
        <DialogContent>
          <p>{dialogContent}</p>
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            onClick={handleClickClose}
            fullWidth
            type="secondary"
          >
            Aceptar
          </ButtonComponent>
        </DialogActions>
      </Dialog>
    </SidebarLayout>
  );
};

export default ExportarReportes;

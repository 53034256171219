import API from './api';

const indicadores = {
  disponibilidadCalculadora: function (conInterceptor = true) {
    return API.axios({
      method: 'get',
      url: '/indicadores/disponibilidad-calculadora',
      interceptor: conInterceptor
    });
  }
};

export default indicadores;

import { ToastComponent } from 'components/Toast/ToastComponent';
import { DICTONARY } from 'const/Dictonary';
import { toast } from 'sonner';
import codigosError from 'states/alertMessages/codigosError';

// Mensajes de Alerta
export const ValidacionConexion = () =>
  toast.custom(toast => (
    <ToastComponent
      type={DICTONARY.ESTADO.WARNING.CLASS}
      mensaje={'Debe iniciar sesión antes de poder ver los residuos.'}
      toast={toast}
    />
  ));

export const AlertaSinRegistros = () =>
  toast.custom(toast => (
    <ToastComponent
      type={DICTONARY.ESTADO.INFO.CLASS}
      mensaje={'No existen registros para la solicitud realizada'}
      toast={toast}
    />
  ));

export const AlertaError = mensaje =>
  toast.custom(toast => (
    <ToastComponent
      type={DICTONARY.ESTADO.ERROR.CLASS}
      mensaje={mensaje}
      toast={toast}
    />
  ));
export const AlertaWarning = mensaje =>
  toast.custom(toast => (
    <ToastComponent
      type={DICTONARY.ESTADO.WARNING.CLASS}
      mensaje={mensaje}
      toast={toast}
    />
  ));
export const AlertaInfo = mensaje =>
  toast.custom(toast => (
    <ToastComponent
      type={DICTONARY.ESTADO.INFO.CLASS}
      mensaje={mensaje}
      toast={toast}
    />
  ));
export const AlertaExito = mensaje =>
  toast.custom(toast => (
    <ToastComponent
      type={DICTONARY.ESTADO.SUCCESS.CLASS}
      mensaje={mensaje}
      toast={toast}
    />
  ));

export const Alerta = codigoError => {
  if (!codigoError) {
    return;
  }

  const error = codigosError[codigoError];
  const mensaje = (
    <div className="flex flex-col">
      <span>Código mensaje: {error.codigoError}</span>
      <span className="font-bold">{error.mensaje}</span>
    </div>
  );

  switch (error.tipo) {
    case DICTONARY.ESTADO.INFO.CLASS:
      AlertaInfo(mensaje);
      break;
    case DICTONARY.ESTADO.SUCCESS.CLASS:
      AlertaExito(mensaje);
      break;
    case DICTONARY.ESTADO.WARNING.CLASS:
      AlertaWarning(mensaje);
      break;
    default:
      AlertaError(mensaje);
  }
};

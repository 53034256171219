// import { CircularProgress } from '@mui/material';
import { Cifra } from './Cifra';
import Text from 'components/design-system/Text/Text';
import Loading from 'components/Loading/Loading';

export const Indicador = ({
  heading,
  image,
  imageAlt,
  element,
  description,
  isLoading
}) => {
  return (
    <div className="w-1/2 md:w-1/4 my-4 md:my-0 text-center">
      {heading}
      <div className="indice-data align-items-center mt-2">
        <img src={image} alt={imageAlt} height="36" className="mx-auto mb-2" />

        {isLoading ? (
          // <CircularProgress
          //   thickness={6}
          //   size={26}
          //   className="text-uv-primary-0 relative z-10 visible"
          // />
          <Loading
            classWrapper="wrapperLoadingIndicadores"
            classChase="sk-chaseIndicadores"
          />
        ) : (
          <Cifra valor={element} />
        )}

        <Text className="medicion">{description}</Text>
      </div>
    </div>
  );
};

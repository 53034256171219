import { AuthContext } from 'context';
import { useContext, useEffect, useState } from 'react';

export const useObtieneCodigoEmpresa = ({ setFieldTouched }) => {
  const {
    logeduser,
    perfiles: { isAdmin }
  } = useContext(AuthContext);
  const [codigoEmpresaState, setCodigoEmpresaState] = useState(null);
  // Obtengo Codigo Empresa
  useEffect(() => {
    setFieldTouched('codigoSucursal');
    setFieldTouched('nombreSucursal');
    if (!logeduser) {
      return;
    }
    if (isAdmin) {
      setCodigoEmpresaState(logeduser.sucursal?.empresa.codigoEmpresa);
    } else {
      const codigoEmpresa = logeduser.sucursal?.empresa?.codigoEmpresa;
      setCodigoEmpresaState(codigoEmpresa);
    }
  }, [isAdmin, setFieldTouched]);

  return {
    codigoEmpresaState
  };
};

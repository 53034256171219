import * as yup from 'yup';
import { verificaCorreoRegistrado } from '../helpers/verificaCorreoRegistrado';
import { DICTONARY } from 'const/Dictonary';

const emailRegex = DICTONARY.EMAIL_REGEX;

let  respuestaFinal ; 

const schemaSignUp = yup.object().shape({
  name: yup
    .string()
    .trim()
    .matches(
      /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+\s[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+\s[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/,
      'El nombre debe contener al menos el primer nombre, apellido paterno y apellido materno'
    )
    .required('Este campo es requerido'),

  email: yup
  .string()
  .trim()
  .matches(emailRegex, 'El correo electrónico no tiene el formato correcto')
  .required('El correo electrónico es requerido')
  .test('Evaluación de correo', 'El correo electrónico ingresado está registrado', 
  async (value, { parent }) => {
    // se agrega  value para que se pueda evaluar el correo ingresado automaticamente
    const emailModificado = parent.emailModificado || value;

    const setIsLoadingEmail = parent.setIsLoadingEmail || (() => {});

    let emailValido = emailRegex.test(value.trim());

    if(emailValido && emailModificado){
      setIsLoadingEmail(true);
      respuestaFinal = await verificaCorreoRegistrado(value).then((resp) => !resp);
      setIsLoadingEmail(false);
    } 

    return respuestaFinal;

  }),

  password1: yup
    .string()
    .trim()
    .test(
      'length',
      'La contraseña debe tener al menos 8 caracteres',
      value => value.length >= 8
    )
    .matches(/([0-9])/, 'numero')
    .matches(/([A-Z])/, 'mayuscula')
    .matches(/([a-z])/, 'minuscula')
    .matches(/([!@#$%^&*(),.?":{}|<>])/, 'caracter')
    .required('La contraseña es requerida'),

  password2: yup
    .string()
    .trim()
    .oneOf([yup.ref('password1'), null], 'Las contraseñas no coinciden')
    .required('Confirme la contraseña')
});



export { schemaSignUp };



